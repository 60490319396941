import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import {MultiSelectModule} from 'primeng/multiselect';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SliderModule} from 'primeng/slider';
import {ButtonModule} from 'primeng/button';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BadgeModule} from 'primeng/badge';
import {GalleriaModule} from 'primeng/galleria';
import {ToastModule} from 'primeng/toast';
import {StepsModule} from 'primeng/steps';
import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {DataViewModule} from 'primeng/dataview';
import { CoreComponent } from './core/core.component';
import { AuthComponent } from './core/auth/auth.component';
import { LoginComponent } from './core/auth/login/login.component';
import { RegisterComponent } from './core/auth/register/register.component';
import { ProfileComponent } from './core/profile/profile.component';
// import { HomeComponent } from './core/home/home.component';
import {RippleModule} from 'primeng/ripple';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { NgOtpInputModule } from 'ng-otp-input';

import {CarouselModule} from 'primeng/carousel';
/* import { TooltipModule } from 'ngx-bootstrap/tooltip'; */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import {
  RiHome3Line,
  RiFacebookCircleFill,
  RiGoogleFill,
  RiFacebookFill,
  RiAppleFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiCreativeCommonsLine,
  RiWhatsappLine,
  RemixIconModule,
  RiYoutubeFill,
} from 'angular-remix-icon';
import { SharedModule } from './shared/shared.module';

const icons = {
  RiHome3Line,
  RiFacebookCircleFill,
  RiGoogleFill,
  RiFacebookFill,
  RiInstagramFill,
  RiAppleFill,
  RiLinkedinBoxFill,
  RiWhatsappLine,
  RiCreativeCommonsLine,
  RiYoutubeFill,
};

@NgModule({
    declarations: [
        AppComponent,
        CoreComponent,
        AuthComponent,
        LoginComponent,
        RegisterComponent,
        ProfileComponent,
        // HomeComponent,
    ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('954712433451-cugu39t4js4492hvuohhser5fkipr26p.apps.googleusercontent.com')
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('119709616813475')
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
            
        },
        {provide: 'googleTagManagerId',  useValue: environment.GTM_ID},
          ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        CascadeSelectModule,
        DropdownModule,
        InputTextModule,
        InputNumberModule,
        MultiSelectModule,
        RadioButtonModule,
        SliderModule,
        ButtonModule,
        ProgressSpinnerModule,
        BadgeModule,
        GalleriaModule,
        ToastModule,
        StepsModule,
        /* TooltipModule, */
        DialogModule,
        TableModule,
        DataViewModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        RemixIconModule.configure(icons),
        /* TooltipModule, */
        SocialLoginModule,
        NgOtpInputModule,
        SharedModule,
        CarouselModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ]
})
export class AppModule { }
