<div class="grid p-0 flex justify-content-center">
    <div class="col-10 md:col-12 lg:col-12 xl:col-12 p-0 " style="height: 50px;">
        <div class="p-inputgroup p-0 grid" style="height: 100%;">
            <div class="col-2" style = "padding-right: 0;padding-left: 0;" >
                <p-inputNumber [ngStyle]="{'margin-left':0, 'margin-right':0, 'width': (otpVerified || alreadyWhats)  ? '70px' : '70px', 
                'pointer-events' : (otpVerified || alreadyWhats) ? 'none' : 'initial','height': '100%',
                'opacity': (otpVerified || alreadyWhats)  ? '0.5' : 'initial'}" prefix="+"  [(ngModel)]="countryCode"
                [useGrouping]="false" [max]="999"></p-inputNumber>
            </div>
            <div class = "col-6 " style = "padding-left: 0;">
                <p-inputNumber [ngStyle]="{'margin-left':0, 'margin-right':0, 'width': (otpVerified || alreadyWhats)  ? '350px' : '280px', 
                'pointer-events' : (otpVerified || alreadyWhats) ? 'none' : 'initial', 'height': '100%',
                'opacity': (otpVerified || alreadyWhats)  ? '0.5' : 'initial'}" [useGrouping]="false"
                [(ngModel)]="phoneNumber"></p-inputNumber>
            </div>
            <div class="col-4 ">
                <p-button pRipple label="Enviar SMS" class="button-envarsms" (click)="showModalDialog(false)" *ngIf="isRegister ? !otpVerified : true"></p-button>
                <p-button pRipple class="button-envarsms checkButton" *ngIf="isRegister ? otpVerified : false">
                    <span class="material-symbols-outlined fill font-container">check_circle</span>
                </p-button>
            </div>
        </div>
    </div>
    <span *ngIf="isProfile" class="material-symbols-rounded iconDialog fill font-container mt-2" 
    style="display: flex; align-items: center; margin-left: 10px;"
    (click)="Edit()">edit</span>
</div>
<div class="grid flex justify-content-center mt-3" *ngIf="secondTime && !otpVerified">
    <div class="col-10 md:col-12 lg:col-12 xl:col-12 ">
        <p-button pRipple class="button-envarsms button-enviarwhats" (click)="!otpVerified ? showModalDialog(true): undefined">
            Solicitar mi código por whatsapp
            <i class="pi pi-whatsapp socialMedia ml-2"></i>
        </p-button>
    </div>
</div>

<p style="color:var(--rojo-zaito-1); text-align: center;" *ngIf="smsValidation !== ''">
    {{smsValidation}} </p>


<p-dialog [(visible)]="displayModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [dismissableMask]="true" [closable]="false" class="phoneDialog">

    <ng-template pTemplate="header">
        <div style="display: flex; justify-content: end; width: 100%;">
            <span class="material-symbols-rounded iconDialog fill font-container" (click)="dissmissModal()">cancel</span>
        </div>
    </ng-template>

    <p *ngIf="displayModal" style="margin-block-end: 0em; font-weight: 500; color: black; padding:0 30px;">Confirma el código de verificación enviado al número</p>
    <p *ngIf="displayModal" style="margin-block-start: 0em; font-weight: 500; color: black; padding:0 30px;">{{phoneNumber?.internationalNumber}}</p>

    <div style="display:flex; justify-content: center "  *ngIf = "showInput">
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config" >
        </ng-otp-input>
    </div>

    <p style="font-weight: 500; text-align: center; color: var(--azul-zaito-5); display: block; 
    margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px;" *ngIf = "count > 0">
        Tiempo de verificación: <span id="seconds">{{count}}</span>
    </p>

    <ng-template pTemplate="footer">
        <div class="grid" style="width: 90%; " class = "mt-3">
            <div class="div col-12" style="display: flex; justify-content:center;">
                <p-button *ngIf="!trySendCodeAgain" [disabled]="!otpInputFilled" class="buttonOTP1"
                    (click)="otpInputFilled ? verifyOTPInput() : false" label="Confirmar">
                </p-button>
                <p-button *ngIf="trySendCodeAgain" (click)="sendOTP('smsAgain')" [disabled]="false" class="buttonOTP2"
                    label="Enviar SMS nuevamente">
                </p-button>
            </div>
            <div class="div col-12" *ngIf="otpValidation !== ''">
                <p style="color:var(--rojo-zaito-1); text-align: center; margin-block-start: 0em; margin-block-end: 0em;">
                    {{otpValidation}} </p>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- <div>
    <p-toast></p-toast>
</div> -->