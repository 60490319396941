import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  src: string;
  zoom = 1;
  tittle : string;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.route.queryParams.subscribe(async (params: any) => {
      if(params.type === 'tyc'){
        this.tittle = "TÉRMINOS Y CONDICIONES";
        this.src = "https://zaitostorage.blob.core.windows.net/privacyandtc/TÉRMINOS Y CONDICIONES USUARIOS V2.0.pdf"
      }else{
        this.tittle = "POLITÍCA DE PROTECCIÓN DE DATOS PERSONALES";
        this.src = "https://zaitostorage.blob.core.windows.net/privacyandtc/POLITICA DE PROTECCION DE DATOS PERSONALES ZAITO V 2.0.pdf"
      }
    })
  }

  ngOnInit(): void {
    
  }

  zoom_in() {
    this.zoom = this.zoom + 0.25;
  }

  zoom_out() {
    if (this.zoom > 1) {
       this.zoom = this.zoom - 0.25;
    }
  }

  download(){
    window.open(this.src);
  }

  pdfUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
  }
}
