import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddiService {

  token = ""


  httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });

  url = environment.shared_url;

  backUrl = environment.server_url;

  constructor(private http: HttpClient) { }

  checkPrices(price: any): Observable<any>{
    const addi_url = "https://channels-public-api.addi.com/allies/zaito-ecommerce/config?requestedAmount=" + price;
    const headers = new HttpHeaders({ "Content-Type": "application/json"})
    return this.http.get(addi_url, { headers: headers });
  }

  getLink(sell: string): Observable<any>{
    const body = {
      sell
    }
    const headers = new HttpHeaders({ "Content-Type": "application/json"})
    return this.http.post(this.url + "GetRedirectAddiLink?code=dg7VpeVDOvhyr1I7QI9arhBRHzwl63YV-CMVj-4AmAReAzFuIBmmGw%3D%3D", body, { headers: headers });
  }

  finishDate(body: any, id: number):  Observable<any>{
    const headers = new HttpHeaders({ "Content-Type": "application/json"})
    return this.http.patch(this.backUrl + "payment/payDate/" + id + "/", body, { headers: headers });
  }

}
