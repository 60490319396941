import { Component, OnInit, ViewChild, Input, OnDestroy,SimpleChanges } from '@angular/core';
import { getAuth, PhoneAuthProvider, signInWithCredential, signInWithPhoneNumber } from "firebase/auth";
import { Subscription, timer } from "rxjs";
import { takeWhile, tap } from "rxjs/operators";
import { AuthUserService } from 'src/app/services/auth/login-register/auth-user.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DocumentSnapshot } from 'firebase/firestore';
import { TwilioService } from 'src/app/services/twilio/twilio.service';


@Component({
  selector: 'app-phone-validation',
  templateUrl: './phone-validation.component.html',
  styleUrls: ['./phone-validation.component.scss']
})
export class PhoneValidationComponent implements OnInit, OnDestroy {

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  countryCode = "57";
  phonenumberAux = "";

  separateDialCode = true;
  phoneNumber!: any;
  displayModal!: boolean;
  recaptchaID!: string;
  otp!: string;
  otpInputFilled!: boolean;
  verifyngOtp = false;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @Input() isRegister!: boolean;
  @Input() isProfile!: boolean;
  @Input() recaptchaVerifier!: any;
  @Input() phoneCel!: any;
  trySendCodeAgain = false;
  count!: number;
  activeCode$!: number;
  firstTime !: Boolean;
  smsValidation = "";
  otpValidation = "";
  attemptsOTPInput = 3;
  timer!: any;
  subscriptionOtpVerified!: Subscription;
  otpVerified = false;
  alreadyWhats = false;

  secondTime = false;

  showInput = false;

  constructor(
    private authUser: AuthUserService,
    private router: Router,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService,
    private twilio: TwilioService
  ) { }

  ngOnInit(): void {
    const auth = getAuth();
    const isMobile = this.deviceService.isMobile();
    if (isMobile){
      this.config.inputStyles.height = '35px';
      this.config.inputStyles.width = '35px';
    }
    this.otpInputFilled = false;
    this.authUser.ObservOtpVerified.next(false);
    this.subscriptionOtpVerified = this.authUser.ObservOtpVerified.subscribe((res) => {
      this.otpVerified = res;
    });

  }

  parsePhoneNumber(phone: string): { countryCode: string ; number: string } {
    // Regular expression to match country code and number
    const phoneStr = String(phone);
    const phoneRegex = /^(\+\d+)?(\d{10,15})$/;
  
    // Match the phone number against the regex
    const match = phoneStr.match(phoneRegex);

  
    // If match is found, separate the country code and the number
    if (match) {
      let countryCode = match[1] ? match[1].replace('+', '') : '57'; // Default to +57 if no country code
      const number = match[2];
      return { countryCode, number };
    }else{
      return {countryCode: "", number: ""};
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.isProfile && this.phoneCel){
      const aux = this.parsePhoneNumber(this.phoneCel)
      this.phoneNumber = aux.number;
      this.countryCode = aux.countryCode
      this.otpVerified = true;
    }
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    if (this.subscriptionOtpVerified) {
      this.subscriptionOtpVerified.unsubscribe();
    }
  }

  startCount(): void {
    this.activeCode$ = 90;
    this.timer = timer(1000, 1000).pipe(takeWhile(() => this.activeCode$ > 0, false),
      tap(() => this.activeCode$--)).subscribe(() => {
        this.count = this.activeCode$;
        if (this.count === 0) {
          this.trySendCodeAgain = true;
          this.secondTime = true;
          this.otpValidation = "No has verificado el código a tiempo."
          this.dissmissModal();
        }
      });
  }

  showModalDialog(isWhats: any) {
    const phoneNumber = this.phoneNumber?.number ? this.phoneNumber.number.toString().replace(" ", "").length : 0
    this.displayModal = true; 
    this.smsValidation = ""
    if(!isWhats){
      this.sendOTP("firstTime");
    }else{
      this.count = 0;
      this.goToWhats();
      this.alreadyWhats = true;
      this.showInput = true;
    }
    
  }


  dissmissModal() {
    this.displayModal = false;
    this.verifyngOtp = false;
    this.activeCode$ = 0;
    this.otpInputFilled = false;
    this.ngOtpInputRef.setValue('');
    this.trySendCodeAgain = false;
    this.attemptsOTPInput = 3;
    this.secondTime = true;
    this.otpValidation = ""
  }
  
  onOtpChange(otpCode: any) {
    this.otp = otpCode;
    this.otpInputFilled = this.otp.length === 6 ? true : false;
  }

  goToWhats(){
    let auxstr = "Quiero solicitar mi código de verificación para el teléfono " + this.phoneNumber?.internationalNumber;
    window.open('https://wa.me/573044210216?text=' + auxstr, '_blank');
  }


  sendOTP(instruction: string) {
    if (instruction === "smsAgain") {
      this.trySendCodeAgain = false;
      this.attemptsOTPInput = 3;
      this.otpValidation = ""
    }
    if(this.timer) this.timer.unsubscribe();
    const phoneNumber = "+" + this.countryCode + this.phoneNumber;
    const auth = getAuth();
    this.verifyngOtp = true;
    const recaptcha = this.recaptchaVerifier;
    this.twilio.sendSmsConfirmation(phoneNumber).then((confirmationResult: any) => {
      this.startCount();
      this.messageService.add({ 
        severity: 'success', summary: 'Código enviado', 
        detail: 'El código se ha enviado exitosamente a tu teléfono' 
      });
      localStorage.setItem(
        'verificationId',
        JSON.stringify(confirmationResult.data)
      );
      this.showInput = true;
    }).catch((error) => {
      this.messageService.add({ 
        severity: 'error', summary: 'Error', 
        detail: 'El número no existe o has intentado muchas veces.' 
      });
    }); 
  }

  async verifyOTPInput() {
    this.attemptsOTPInput--;
    if (this.attemptsOTPInput < 1){
      this.trySendCodeAgain = true;
      this.timer.unsubscribe();
      this.otpValidation = "*Has intentado muchas veces."
    }
    const auth = getAuth();
    const phoneNumber = "+" + this.countryCode + this.phoneNumber;
    const verificationId = JSON.parse(localStorage.getItem('verificationId') || '{}');
    var credential = PhoneAuthProvider.credential(
      verificationId,
      this.otp
    );
    
    this.twilio.sendSmsVerification(phoneNumber, this.otp).then((data) => {
      this.messageService.add({ 
        severity: 'success', summary: 'Código verificado', 
        detail: 'El código ingresado ha sido verificado exitosamente' 
      });
      this.authUser.formDigitedPhone = phoneNumber;
      this.authUser.savePhoneAndCodeFirebase(this.otp, phoneNumber).then(() => {
        this.authUser.loginWithPhone(this.otp, phoneNumber)
          .then((resp) => {
            if (resp) {
              this.authUser.ObservOtpVerified.next(true); 
              if (!this.isProfile){
                this.router.navigate(["/home"])
              } 
            } else {
              if (!this.isRegister){
                this.messageService.add({ severity: 'error', summary:  'Error', detail: 'Usuario no encontrado, debes registrarte' });
              } else{ 
                this.authUser.ObservOtpVerified.next(true); 
              }
            }
            this.authUser.user.phoneNumber = phoneNumber.replace(" ", "")
            this.authUser.deletePhoneAndCodeFirebase(phoneNumber);
            this.dissmissModal();
          }).catch((error) => {
            console.log(error);
          })
      })
      .catch(error => {
        console.log("Error guardando datos en firebase", error);
      });
    }).catch(async (error) => {
      this.authUser.checkFirebaseCustomCodes(this.otp).then((docSnapshot: DocumentSnapshot<any>) => {
        if (docSnapshot.exists()) {
          let docData = docSnapshot.data();
          if (docData.data){
            this.messageService.add({ 
              severity: 'success', summary: 'Código verificado', 
              detail: 'El código ingresado ha sido verificado exitosamente' 
            });
            this.authUser.formDigitedPhone = phoneNumber;
            localStorage.removeItem("verificationId")
              this.authUser.savePhoneAndCodeFirebase(this.otp, phoneNumber)
                .then(() => {
                  this.authUser.loginWithPhone(this.otp, phoneNumber)
                    .then((resp) => {
                      if (resp) {
                        this.authUser.ObservOtpVerified.next(true); 
                        if (!this.isProfile){
                          this.router.navigate(["/home"])
                        } 
                      } else {
                        if (!this.isRegister){
                          this.messageService.add({ severity: 'error', summary:  'Error', detail: 'Usuario no encontrado, debes registrarte' });
                        } else{ 
                          this.authUser.ObservOtpVerified.next(true); 
                        }
                      }
                      this.authUser.user.phoneNumber = this.phoneNumber.number.toString().replace(" ", "")
                      this.authUser.deletePhoneAndCodeFirebase(phoneNumber);
                      this.dissmissModal();
                    }).catch((error) => {
                      console.log(error);
                    })
                })
                .catch(error => {
                  console.log("Error guardando datos en firebase", error);
                });
          }else{
            this.messageService.add({ severity: 'error', summary:  'Código inválido', detail: 'El código ingresado es inválido' });
            this.otpValidation = this.attemptsOTPInput < 1 ? "*Has intentado muchas veces." : "*código inválido";
          }
        } else {
          this.messageService.add({ severity: 'error', summary:  'Código inválido', detail: 'El código ingresado es inválido' });
          this.otpValidation = this.attemptsOTPInput < 1 ? "*Has intentado muchas veces." : "*código inválido";
        }
      }).catch((error) =>{
        console.log("Message error", error.message, "Error código ingresado.", error);
        this.messageService.add({ severity: 'error', summary:  'Código inválido', detail: 'El código ingresado es inválido' });
        this.otpValidation = this.attemptsOTPInput < 1 ? "*Has intentado muchas veces." : "*código inválido";
      });
      
    });
  }

  Edit(){
    this.authUser.ObservOtpVerified.next(false);
  }

}
