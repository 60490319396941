import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { DatesService } from 'src/app/services/dates/dates.service';

@Component({
  selector: 'app-calendar-product-card',
  templateUrl: './calendar-product-card.component.html',
  styleUrls: ['./calendar-product-card.component.scss']
})
export class CalendarProductCardComponent {

  @Input() product: any;
  @Input() i: any;
  @Input() variant: any;

  @ViewChild('calendar') calendar:any;

  @Output() emitDate = new EventEmitter<{product: any, day: Date, index: number, variant: number}>();
  

  dates:any[] = [];
  schedules: any[] = [];

  selectedDay: any;

  selectedHour: any;

  selectedHourindex: any;

  minDate;
  maxDate;

  firstTIme = true;


  loadingDates = false;


  constructor(private dateService: DatesService, private config: PrimeNGConfig,) {
    this.config.setTranslation( {
      dayNames: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      dayNamesShort: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      dayNamesMin: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
          'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      today: 'Hoy',
      clear: 'Limpiar',
    });
    const lastDate = new Date();
    const minDate = new Date();
    lastDate.setMonth(lastDate.getMonth() + 1);
    this.maxDate = lastDate;
    this.minDate = minDate;
  }

  ngOnInit(): void{
  }

  formatInstallTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${hours}:${mins.toString().padStart(2, '0')}`;
  }



  async getHours(event: any, productId: any){
    this.loadingDates = true;
    this.firstTIme = false;
    this.selectedHourindex = null;
    const dateaux = new Date(event);
    const date = this.checkDateAndHour(dateaux);
    const isSameDay = (date1: Date, date2: Date) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
    
    const isBeforeDay = (date1: Date, date2: Date) =>
      date1.getFullYear() < date2.getFullYear() ||
      (date1.getFullYear() === date2.getFullYear() &&
        (date1.getMonth() < date2.getMonth() ||
          (date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate())));
    if (isSameDay(dateaux, date) || isBeforeDay(date, dateaux)) {
      // The selected day is the same or later than the calculated date; call postSchedule
      const response = await lastValueFrom(this.dateService.postSchedule(date, productId));
      this.schedules = response.free_hours;
    } else {
      // The selected day is before the calculated date; set schedules to an empty array
      this.schedules = [];
    }
    /* const response = await lastValueFrom(this.dateService.postSchedule(date,productId))
    this.schedules = response.free_hours; */
    this.loadingDates = false;
  }

  checkHours(event: any, index: any){
    if (this.selectedHourindex === null){
      this.selectedDay = null
    }
  }

  selectHour(schedule: any){
    this.selectedDay = new Date(schedule);
    this.emitDate.emit({product: this.product, day: this.selectedDay, index: this.i, variant: this.variant})
    this.calendar.toggle()
  }

  private checkDateAndHour(dateSelected: any): Date {
    const today = new Date();
    const delayedMinutes = this.product.supply_time;
    const todayWithDelay = new Date(today);
    todayWithDelay.setMinutes(todayWithDelay.getMinutes() + delayedMinutes);
    const isSameOrAfter = (date1: Date, date2: Date) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
    if ((isSameOrAfter(dateSelected, today) || dateSelected > today) && dateSelected < todayWithDelay) {
      return todayWithDelay;
    } else {
      return dateSelected;
    }
  }

  separateSupplyTime(supply_time: number): { supply: number; daySupply: number } {
    // Calculate the total minutes in a day
    const minutesPerDay = 24 * 60;
  
    // Extract the daySupply (number of days)
    const daySupply = Math.floor(supply_time / minutesPerDay);
  
    // Calculate remaining minutes for the supply time of day
    const supply = supply_time % minutesPerDay;
  
    return { supply, daySupply };
  }


  getTimeString(date: Date): string {
    if(date){
      const hours = date.getHours();
      const minutes = date.getMinutes();
    
      // Convert hours and minutes to two-digit format
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    
      return `${formattedHours}:${formattedMinutes}`;
    }else{
      return "";
    }
  }

}
