import { Component } from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GarageService } from './services/garage/garage.service';
import { ShopCartService } from './services/shop/shop-cart.service';
import { Carousel } from 'primeng/carousel';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from './services/pixel/pixel.service';
import * as WebFont from 'webfontloader';
import { ToastService } from './services/toast-service/toast.service';
import { AccountService } from './services/shared/account/account.service';
import { UserprofileService } from './services/userProfile/userprofile.service';

declare const gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConfirmationService,MessageService]
})
export class AppComponent {

  aceptedCookies!: boolean;
  width!: number;

  mobileType: string;
  islogged = false;


  constructor(private shopCartService: ShopCartService,
              private deviceService: DeviceDetectorService,
              private router: Router,
              private gtmService: GoogleTagManagerService,
              private pixel: PixelService,
              public messageService: MessageService,
              private toast: ToastService,
              private accountInfo: AccountService,
              private userservice: UserprofileService
              ) {
                Carousel.prototype.onTouchMove = () => { };
              }

  async ngOnInit() {
    WebFont.load({ 
        google: { families: [ 'Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
                              'Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
                              'Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200' ] },
        active: () => {
          document.body.classList.add('fonts-loaded');
        }
    })
    await this.shopCartService.loadCar();
    await this.shopCartService.countNumberProductsInCart();
    const c: any = localStorage.getItem('cookies')
    const f = new Date() < new Date(JSON.parse(c)?.expire)
    if(JSON.parse(c)?.value == 'acepted' && f){
      this.aceptedCookies = true;
    }
    const isMobile = this.deviceService.isMobile();
    this.width = screen.width;
    
    if (isMobile){
      const info = this.deviceService.getDeviceInfo()
      this.mobileType = info.os;
    }
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page_view',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
        this.pixel.track('PageView', {
          content_name: item.url,
          content_category: 'page',
          content_ids: [item.url]
        });
      }
    });
    this.islogged = await this.accountInfo.isLogged();
    if (this.islogged){
      this.userservice.getInfoUser().subscribe((data) => {
        const user = data[0]
        gtag('set', 'user_data', {
          'email': user.auth_user.email,
          'phone_number': user.celphone,
          'address': {'first_name': user.auth_user.first_name, 'last_name': user.auth_user.last_name, 'country': 'Colombia'}
        });
      })
    }
    this.toast.toastError.subscribe((data) => {
      if(data.length > 0){
        this.messageService.add({
          severity: "error",
          summary: "Error!",
          detail: data,
          life: 6000
        });
      }
    })
    this.toast.toastSuccess.subscribe((data) => {
      if(data.length > 0){
        this.messageService.add({
          severity: "success",
          detail: data,
          life: 6000
        });
      }
    })
  }

  hasAcepted($event:any){
    if($event == 'acepted' || $event == 'closed'){
      this.aceptedCookies = true;
    }
  }

}
