import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MessageService } from 'primeng/api';
import { FavoriteService } from 'src/app/services/favorite/favorite.service';
import { PixelService } from 'src/app/services/pixel/pixel.service';
import { AccountService } from 'src/app/services/shared/account/account.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit{

  @Input() product: any;
  @Input() center: boolean = false;

  adImgs = ["../../../assets/img/shop/TagProm.svg",
            "../../../assets/img/shop/TagPercent.svg",
            "../../../assets/img/shop/TagOff.svg"];

  showIcon = false;
  islogged:boolean = false;
  isFavoriteForGuest:boolean = false;

  isMobile

  constructor(private router: Router,
              private favoriteService: FavoriteService,
              private accountInfo: AccountService,
              private messageService: MessageService,
              private generalConfigurationService: GeneralConfigurationService,
              private gtmService: GoogleTagManagerService,
              private pixel: PixelService,) {
                this.isMobile = this.generalConfigurationService.isMobile;
  }

  async ngOnInit() {
    this.islogged = await this.accountInfo.isLogged();
  }

  get completePrice() {
    let price = this.originalPrice;
    if(this.product.productAd[0]?.type == 1){
      price = price - (price * (this.product.productAd[0].discount / 100));
    }else if(this.product.productAd[0]?.type == 2){
      price = price - (this.product.productAd[0].offer);
    }
    return price
  }

  get originalPrice(){
    let price = this.product.prize;
    if(this.product.tax){
      price = price * (1 + (this.product.tax / 100))
    }
    return price
  }

  mouseIn(){
    this.showIcon = true;
  }

  mouseOut(){
    this.showIcon = false;
  }

  addWishListTrigg(){
    const gtmTag = {
      event: 'add_to_wishlist',
      data: {currency: 'COP', value: this.originalPrice, 
        items: [
          {
            item_id: this.product.idproduct,
            item_name: this.product.name
          }
        ]},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('AddToWishlist', {
      content_category: 'page',
      content_name : 'AddToWishlist',
      currency: 'COP',
      value: this.originalPrice,
      content_ids: this.product.idproduct
    });
  }

  markFavorite() {
    const token = localStorage.getItem("token");
    if(token && (this.product?.is_wished == null) || token && (this.product?.is_wished.length == 0)) {
      this.favoriteService.markAsFavorite(this.product.idproduct).subscribe((data: any) => {
        this.addWishListTrigg();
        this.product.is_wished = [data.id_wish];
        this.messageService.add({ 
          severity: 'success', summary: 'Favoritos', 
          detail: this.product.name + ' Se ha agregado a favoritos',
          life: 3000
        });
      });
    } else if (token) {
      this.favoriteService.deleteFavorite(this.product.is_wished[0]).subscribe((data: any) => {
        this.product.is_wished = data;
      });
    } else if (token == null) {
      this.router.navigate(['login']);
    }
  }

  goToProduct() {
    return '/product?id=' + this.product.idproduct
    /* this.router.navigate(['/product'], {queryParams: {id : this.product.idproduct}}); */
  }

}
