<ng-container *ngIf="!showSkeleton; else skeletonChanged">
  <div *ngIf="deliveryPackagesParcelCart.length !== 0  || deliveryPackagesCommodityCart.length !== 0 || deliveryPackagesLiquidCart.length !== 0">
    <div class="deliveryAndDate-main">
      <div class="delivery-main-info" size="12">
        <h5 class="m-0">{{type == 1 ? 'Envíos' : (type == 2 ? 'Citas': 'Retiros')}}</h5>
        <h6 *ngIf="type==1">Dirección de Destino</h6>
        <p *ngIf="type==1">{{userAddress.address}}</p>
        <p class="description-text" *ngIf="type==1">{{userAddress.description}}</p>
      </div>
      <div size="12" *ngFor="let packages of deliveryPackagesParcelCart; index as i" class="deliveryAndDate-card-ally">
        <h5>Paquete {{i+1}}</h5>
        <div *ngFor="let delivery of packages.packageToShow" class="deliveryAndDate-card-product">
          <div class="deliveryAndDate-card-value col-12">
            <div class="col-8 p-0">
              <p>{{delivery.name}} -  {{getVariantName(delivery)}}</p>
            </div>
            <div class="col-4 p-0 flex justify-content-end">
              <p class="price"> {{delivery.price | currency: 'COP': 'symbol': '1.0-2'}}</p>
            </div>
          </div>
          <div *ngIf="delivery.discount !== 0" class="deliveryAndDate-card-value col-12">
            <div class="col-6 p-0">
              <p>Descuento</p>
            </div>
            <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
              <p class="discount-price">- {{delivery.discount | currency: 'COP': 'symbol': '1.0-2'}}</p>
              <br>
              <p style="font-weight: 600; color: var(--verde-zaito-2); font-size: 13px;" *ngIf="couponDiscount">
                Cupón {{couponDiscount.value}} Aplicado!
              </p>
            </div>
          </div>
          <div class="deliveryAndDate-card-value col-12" *ngIf="type == 2">
            <div class="col-6 p-0 ">
              <p>Mano de obra y/o instalación</p>
            </div>
            <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
              <p class="price" *ngIf="delivery.installPrice > 0;else include"> {{delivery.installPrice  | currency: 'COP': 'symbol': '1.0-2'}}</p>
              <ng-template #include>
                <p class="price"> Incluida</p>
              </ng-template>
              <br>
            </div>
          </div>
        </div>
        <div class = "col-12 separator">
            
        </div>
        <div class="deliveryAndDate-card-value col-12" *ngIf="type == 1">
          
          <div class="col-6 p-0 ">
            <p>Costos de envío</p>
          </div>
          <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
            <p class="price" *ngIf="packages.deliveryPrice > 0"> {{packages.deliveryPrice | currency: 'COP': 'symbol': '1.0-2'}}</p>
            <p style="font-weight: 600; color: var(--verde-zaito-2); font-size: 13px;" *ngIf="packages.deliveryPrice <= 0"> Gratis</p>
            <br>
            <p style="font-weight: 600; color: var(--verde-zaito-2); font-size: 13px;" *ngIf="this.couponDelivery">
              Cupón {{this.couponDelivery.value}} Aplicado!
            </p>
          </div>
        </div>
        <div class="deliveryAndDate-card-subtotal col-12">
          <div class="col-8 p-0">
            <p>Total</p>
          </div>
          <div class="col-4 p-0 flex justify-content-end">
            <p class="subtotal-price"> {{packages.subTotal | currency: 'COP': 'symbol': '1.0-2'}}</p>
          </div>
        </div>
        <div class="deliveryAndDate-card-time">
          <p *ngIf="packages.shippingDays === 1">
            *Llega aproximadamente en {{packages.shippingDays}} día
          </p>
          <p *ngIf="packages.shippingDays > 1">
            *Llega aproximadamente en {{packages.shippingDays}} días
          </p>
        </div>
      </div>
      <div *ngFor="let packages of deliveryPackagesCommodityCart; index as i" class="deliveryAndDate-card-ally col-12">
        <h5>Paquete {{i+1 + deliveryPackagesParcelCart.length}}</h5>
        <div *ngFor="let delivery of packages.packageToShow" class="deliveryAndDate-card-product">
          <div class="deliveryAndDate-card-value col-12">
            <div class="col-8 p-0">
              <p>{{delivery.name}} -  {{getVariantName(delivery)}}</p>
            </div>
            <div class="col-4 p-0">
              <p class="price"> {{delivery.price | currency: 'COP': 'symbol': '1.0-2'}}</p>
            </div>
          </div>
          <div *ngIf="delivery.discount !== 0" class="deliveryAndDate-card-value col-12">
            <div class="col-6 p-0">
                <p>Descuento</p>
              </div>
              <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
                <p class="discount-price">- {{delivery.discount | currency: 'COP': 'symbol': '1.0-2'}}</p>
              </div>
          </div>
          <div class="deliveryAndDate-card-value col-12" *ngIf="type == 2">
            <div class="col-6 p-0 ">
              <p>Mano de obra y/o instalación</p>
            </div>
            <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
              <p class="price" *ngIf="delivery.installPrice > 0;else include"> {{delivery.installPrice  | currency: 'COP': 'symbol': '1.0-2'}}</p>
              <ng-template #include>
                <p class="price"> Incluida</p>
              </ng-template>
              <br>
            </div>
          </div>
        </div>

        <div class = "col-12 separator">
            
        </div>
        <div class="deliveryAndDate-card-value col-12" *ngIf="type == 1">
            <div class="col-6 p-0">
                <p>Costos de envío</p>
            </div>
            <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
                <p class="price" *ngIf="packages.deliveryPrice > 0"> {{packages.deliveryPrice | currency: 'COP': 'symbol': '1.0-2'}}</p>
                <p style="font-weight: 600; color: var(--verde-zaito-2); font-size: 13px;" *ngIf="packages.deliveryPrice <= 0"> Gratis</p>
            </div>
        </div>
        <div class="deliveryAndDate-card-subtotal col-12">
            <div class="col-8 p-0">
                <p>Total</p>
            </div>
            <div class="col-4 p-0">
                <p class="subtotal-price"> {{packages.subTotal | currency: 'COP': 'symbol': '1.0-2'}}</p>
            </div>
        </div>
        <div class="deliveryAndDate-card-time">
          <p *ngIf="packages.shippingDays === 1">
            *Llega aproximadamente en {{packages.shippingDays}} día
          </p>
          <p *ngIf="packages.shippingDays > 1">
            *Llega aproximadamente en {{packages.shippingDays}} días
          </p>
        </div>
      </div>
      <div size="12" *ngFor="let packages of deliveryPackagesLiquidCart; index as i" class="deliveryAndDate-card-ally">
        <h5>Paquete {{i+1 + deliveryPackagesParcelCart.length}}</h5>
        <div *ngFor="let delivery of packages.packageToShow" class="deliveryAndDate-card-product">
          <div class="deliveryAndDate-card-value col-12">
            <div class="col-8 p-0">
              <p>{{delivery.name}} - {{getVariantName(delivery)}}</p>
            </div>
            <div class="col-4 p-0">
              <p class="price"> {{delivery.price | currency: 'COP': 'symbol': '1.0-2'}}</p>
            </div>
          </div>
          <div *ngIf="delivery.discount !== 0" class="deliveryAndDate-card-value col-12">
            <div class="col-6 p-0">
              <p>Descuento</p>
            </div>
            <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
              <p class="discount-price">- {{delivery.discount | currency: 'COP': 'symbol': '1.0-2'}}</p>
            </div>
          </div>
        </div>
        <div class = "col-12 separator">
            
        </div>
        <div class="deliveryAndDate-card-value col-12" *ngIf="type == 1">
          <div class="col-6 p-0">
            <p>Costos de envío</p>
          </div>
          <div class="col-6 p-0 flex justify-content-end" style = "flex-direction: column;align-items: flex-end;">
            <p class="price" *ngIf="packages.deliveryPrice > 0"> {{packages.deliveryPrice | currency: 'COP': 'symbol': '1.0-2'}}</p>
            <p style="font-weight: 600; color: var(--verde-zaito-2); font-size: 13px;" *ngIf="packages.deliveryPrice <= 0"> Gratis</p>
          </div>
        </div>
        <div class="deliveryAndDate-card-subtotal col-12">
          <div class="col-8 p-0">
            <p>Total</p>
          </div>
          <div class="col-4 p-0">
            <p class="subtotal-price"> {{packages.subTotal | currency: 'COP': 'symbol': '1.0-2'}}</p>
          </div>
        </div>
        <div class="deliveryAndDate-card-time">
          <p *ngIf="packages.shippingDays === 1">
            *Llega aproximadamente en {{packages.shippingDays}} día
          </p>
          <p *ngIf="packages.shippingDays > 1">
            *Llega aproximadamente en {{packages.shippingDays}} días
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="datePackages.length !== 0">
    <div class="deliveryAndDate-main">
      <div *ngFor="let packages of datePackages; index as i" class="deliveryAndDate-card-ally">
        <h5>Cita {{i+1}}</h5>
        <div *ngFor="let date of packages.package" class="deliveryAndDate-card-product">
          <div class="deliveryAndDate-card-value">
            <p>{{date.name}}</p>
            <p class="price"> {{date.price | currency: 'COP': 'symbol': '1.0-2'}}</p>
          </div>
          <div *ngIf="date.discount !== 0" class="deliveryAndDate-card-value">
            <p>Descuento</p>
            <p class="discount-price">- {{date.discount | currency: 'COP': 'symbol': '1.0-2' }}</p>
          </div>
        </div>
        <div class="deliveryAndDate-card-value">
          <p>Mano de obra y/o instalación</p>
          <p class="price" *ngIf = "packages.installPrice > 0; else include"> {{packages.installPrice | currency: 'COP': 'symbol': '1.0-2'}}</p>
          <ng-template #include>
            <p class="price"> Incluida</p>
          </ng-template>
        </div>
        <div class="deliveryAndDate-card-subtotal">
          <p>Total</p>
          <p class="subtotal-price"> {{packages.subTotal | currency: 'COP': 'symbol': '1.0-2'}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #skeletonChanged>
  <app-skeleton-text [value]="2"></app-skeleton-text>
</ng-template>

<div class="col-10 col-offset-1">
  <p-button pRipple
    (onClick)="type != 2 ? paymentDialog = true : openSchedule()"
    [disabled]="!payEnable"
    class="button-continuar">
        <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
            <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
              Continuar
            </div>
            <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                {{(total | number)}}
            </div>
        </div>
    </p-button>
</div>
<p-dialog [(visible)]="sistecreditDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}" >
  <div class = "container">
      <div class = "grid ">
          <div class = "col-12 p-3">
              <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" style = "height: 50%;" >
          </div>
      </div>
      <div class = "grid">
          <div class = "col-12 p-3" style="font-weight: 600;padding-top: 0 !important;">
            Digita tu número de documento para continuar.
          </div>
      </div>
      <div class = "grid" style = "padding-top: 0.5rem !important;">
          <div class = "col-12">
              <p-dropdown [options]="idTypes" optionLabel="value" placeholder="Tipo de documento" class="dropdown-select"  
              [(ngModel)]="idType">
              </p-dropdown>
          </div>
          <div class = "col-12" style = "padding-top: 0.5rem !important;">
              <input [(ngModel)]="idnumber"  pInputText style="width: 100%;">
          </div>
          <div class = "col-12" style = "margin-top: 1rem !important;">
              <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber?.length > 0) || !idType"
              [style] = "{width: '100%'}" (click) = "this.loadingSiste = true;pay()" *ngIf = "!loadingSiste;else sisLoading">
                  <span style="font-weight: 600;">Continuar</span>
              </p-button>
              <ng-template #sisLoading>
                <div class = "col-12 flex justify-content-center" >
                    <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                </div>
              </ng-template>
          </div>
      </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="addiDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}">
  <div class = "container">
      <div class = "grid">
        <div class = "col-12 mb-2">
            <img src="../../../../assets/img/shared/Addi-logo-zai.png">
        </div>
      </div>
      <div class = "grid">
          <div class = "col-12 p-3 mt-2" style="font-weight: 600;padding-top: 0 !important;">
              Digita tu número de documento y correo electrónico para continuar.
          </div>
      </div>
      <div class = "grid" style = "padding-top: 0.5rem !important;">
          <div class = "col-12" style = "padding: 0.5rem;" >
              <p-inputNumber [(ngModel)]="idnumber" placeholder="Numero de Documento" style = "width: 100%;" [useGrouping]="false"/>
          </div>
          <div class = "col-12">
              <input pInputText placeholder="Correo Electrónico" style = "padding:0.75rem 0.75rem; width: 100%;border: 1px solid" [(ngModel)]="email" />
          </div>
      </div>
      <div class = grid>
        <div class = "col-12" style = "padding: 0.5rem;" style = "margin-top: 1rem !important;">
            <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber > 0) || !email"
            [style] = "{width: '100%'}" (click) = "(idnumber > 0) && email ? pay() : ''" *ngIf = "!loadingAddi;else AddiLoading">
                <span style="font-weight: 600;">Continuar</span>
            </p-button>
            <ng-template #AddiLoading>
                <div class = "col-12 flex justify-content-center" >
                    <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                </div>
              </ng-template>
        </div>
      </div>
  </div>
</p-dialog>


<p-dialog [(visible)]="paymentDialog" [modal]="true" [closable] = "true" [showHeader]="false"
    [style]="{ width: isMobile ? '90vw' : '30vw'}" [dismissableMask]="true"  class = "dialogPayment">
    <div class = "container">
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 p-3" style="font-weight: 600;color:black">
                Selecciona el Medio de Pago
            </div>
        </div>
        <div class = "horizontalLine">

        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center"
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'epayco'">
                <p-radioButton name="methods" value="epayco" [(ngModel)]="paymentMethod"></p-radioButton>
                <img src = "../../../assets/img/shared/Medios-pagos-ns.png" style = "height: 2em;" class = "ml-2">
            </div>
        </div>
        <div class = "horizontalLine">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center" 
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'sistecredit'">
                <p-radioButton name="methods" value="sistecredit" [(ngModel)]="paymentMethod"></p-radioButton>
                
                <div class = "paymentBanner ml-2" >
                  <img src = "../../../assets/img/shared/Sitecredito-logo-circle_1.png" style = "height: 28px;margin-right: 0.7em;">
                  Paga hasta <div *ngIf ="!isMobile" style = "margin-left: 0.4em">en</div> 
                  <span style = "color: var(--azul-zaito-5);font-weight: 500; margin-left: 0.3em;margin-right: 0.3em;">4 cuotas </span>
                  con 
                  <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg " style = "height: 9px;margin-left: 0.3em;">
                  <!-- <a style = "color:#0033A0; cursor: pointer;font-weight: 500;" class="underlined"> +Info</a> -->
                </div>
            </div>
        </div>
        <div class = "horizontalLine">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;" *ngIf = "allow_addi">
          <div class = "col-12 flex align-items-center"
          style = "padding-top: 0px;padding-bottom: 0px;;cursor: pointer;" 
          (click) = "paymentMethod = 'addi'">
              <p-radioButton name="methods" value="addi" [(ngModel)]="paymentMethod"></p-radioButton>
              <addi-widget price="{{total}}" ally-slug="zaito-ecommerce" class = "ml-2"></addi-widget>
          </div>
        </div>
        <div class = "horizontalLine" *ngIf = "allow_addi">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;" *ngIf = "type == 1">
          <div class = "col-12 flex align-items-center" *ngIf = "roundSubTotal >= 90000;else nonCoordi"
          style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;">
              <p-radioButton name="methods" value="contraentrega" [(ngModel)]="paymentMethod"></p-radioButton>
              <img src = "../../../assets/img/shared/ContraEntregaCoordinadora.png" class = "ml-3 contraImg">
          </div>
          <ng-template #nonCoordi>
            <div class = "col-12 flex align-items-center"
              style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;">
                  <p-radioButton name="methods" value="contraentrega" [disabled]="true" [(ngModel)]="paymentMethod"></p-radioButton>
                  <img src = "../../../assets/img/shared/MinContraEntregaCoordinadoraX2.png" class = "ml-3 paymentOption">
              </div>
          </ng-template>
        </div>
        <div class = "horizontalLine" *ngIf = "type == 1">
        </div>
        <div class = "grid mt-3 mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12">
                <p-button pRipple
                (onClick)="toPayment()"
                [disabled]="this.paymentMethod === undefined" 
                class="button-continuar">
                    <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
                          Ir a pagar
                        </div>
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                            {{(total | number)}}
                        </div>
                    </div>
                </p-button>
            </div>
            
        </div>
    </div>
</p-dialog>