<div class = "grid">
    <div class = "col-2">
        <img src = "{{product.id_image[0].image_idimage.url ? product.id_image[0].image_idimage.url: product.id_image[0].image_idimage}}" style = "width: 100%;">
    </div>
    <div class = "col-8 sm:col-6 md:col-6 lg:col-6 xl:col-6 "
    style = "font-family: var(--z-primary-font-family);color: black;">
        {{product.name}}
    </div>
</div>
<div class = "grid">
    <div class = "col-8" style = "font-family: var(--z-primary-font-family);color: black;font-weight: 600;font-size: 1em;">
        Tiempo estimado de la cita
    </div>
</div>
<div class = "grid">
    <div class = "col-7 ml-2" style = "font-family: var(--z-primary-font-family);color: black;font-size: 1em;">
        {{formatInstallTime(product.installTime)}} h:mm
    </div>
</div>
<div class = "grid">
    <div class = "col-7" style = "font-family: var(--z-primary-font-family);color: black;font-weight: 600;font-size: 1em;">
        Fecha de la Cita
    </div>
</div>
<div class = "grid">
    <div class = "col-11 sm:col-7 md:col-7 lg:col-7 xl:col-7" style = "font-family: var(--z-primary-font-family);color: black;font-weight: 600;font-size: 1em;">
        <p-calendar [(ngModel)]="selectedDay" [showIcon]="true" (onClose) = "checkHours($event, i)"  #calendar placeholder="Seleccionar Horario de la Cita"
        appendTo="body" (onSelect) = "getHours($event,product.idproduct)" dateFormat="dd/mm/yy {{getTimeString(selectedDay)}}"
        [hideOnDateTimeSelect]="false" [minDate]="minDate" [maxDate]="maxDate" [readonlyInput]="true">
            <ng-template pTemplate="footer">
                <div class="scroll-container" *ngIf = "!loadingDates;else loadingDatesTemp">
                    <div *ngFor="let schedule of schedules index as j" 
                    [ngClass]="j === selectedHourindex ? 'selected-time-slot':'time-slot'"  
                    (click) = "selectedHourindex = j;selectedHour = schedule; selectHour(schedule)">
                        {{schedule | date:"H:mm"}}
                      </div>
                    <div *ngIf ="!firstTIme && schedules.length == 0">
                        No hay horarios disponibles para el día seleccionado
                    </div>
                </div>
                <ng-template #loadingDatesTemp>
                    <div class = "grid">
                        <div clasS = "col-12" style = "display: flex;justify-content: center;">
                            <img src = "assets/img/shared/loading.gif" style = "width: 2rem;height: 2rem;" alt="loading">
                        </div>
                    </div>
                </ng-template>
                <div class = "grid mt-2">
                    <div class = "col-12" 
                    style = "font-weight: 600;display: flex;justify-content: center;color:var(--azul-zaito-5)">
                        *Selecciona un {{schedules.length >0 ? "Horario": "Día"}} para continuar
                    </div>
                </div>
            </ng-template>
        </p-calendar>
    </div>
</div>