<div class = "container containeraux">
    <div class = "grid">
        <div class = "col-12">
            <div class = "grid">
                <div class = "col-9 mb-3" 
                style = "font-size: 1.5em;color:black;font-weight: 600;">
                    Agendamiento de Cita
                </div>
                <div class = "col-3 mb-3" >
                    <span class="material-symbols-rounded fill icon-dialogs " 
                    style = "cursor: pointer;"
                    (click) ="closeModal(1, false)" >cancel</span>
                </div>
                <div class = "col-12" style = "font-size: 1.3em;color:black;font-weight: 600;">
                    Ubicación
                </div>
                <div class = "col-12 map-container">
                    <a  href="https://www.google.com/maps/place/Zaito/@6.1788948,-75.5960059,17z/data=!3m1!4b1!4m6!3m5!1s0x8e4682803c811f6d:0x8a26fa94e8f49c2f!8m2!3d6.1788948!4d-75.593431!16s%2Fg%2F11rylmqnrc?hl=es-419&entry=ttu" target="_blank">
                        <div class = "col-12 map-wrapper">
                            <div class = "mapTitle">
                                <div class = "grid" style = "width: 100%;">
                                    <div class = "col-9" style = "font-size: 0.7em;text-align: center;">
                                        Carrera 42 # 67a -191 interior 114-2<br>
                                        Centro Empresarial Capricentro Itagüi-Antioquia
                                    </div>
                                    <div class = "col-3 flex align-items-center justify-content-center">
                                        <img style = "width: 50%;height: 100%;"
                                        src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Google_Maps_Logo_2020.svg/512px-Google_Maps_Logo_2020.svg.png">
                                    </div>
                                </div>
                            </div>
                            <div #map2 id="map2" [class.map-loaded]="mapLoaded" *ngIf="googleMapAPILoaded"></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class = "col-12" *ngFor="let product of products index as i">
            <app-calendar-product-card (emitDate)=addDate($event)
            [product]="product"
            [i]="i"
            [variant]="variants[i]">
            </app-calendar-product-card>
        </div>
    </div>
    <!-- <div class = "horizontalLine" style = "margin-top: 2%;margin-bottom: 2.5%;margin-left: -4em;width: 150%;height: 10px !important;background-color: var(--gris-zaito-3);">
    </div> -->
    <div class = "grid">
        <div class = "col-12">
            <div class = "grid">
                <div class ="col-12 paymentAdvice">
                    *El pago total se realiza al final de la cita; en Zaito el cumplimiento
                    es primordial para garantizarte un excelente servicio
                </div>
                <div class ="col-12" style = "display: flex;justify-content: center;padding-top: 0rem;">
                    <img src = "../../../assets/img/shared/Metodos-de-Pago-3.0-Citas.png" style = "width: 60%;">
                </div>
            </div>
            <div class = "grid">
                <div class = "col-6" style = "color:black;font-size: 0.9em;font-weight: 600;">
                    Subtotal
                </div>
                <div class = "col-6" 
                style = "display: flex;justify-content: end;color:black;font-size: 0.9em;font-weight: 600;">
                    + {{calSubTotal() | currency:'USD':'symbol' : '1.0-5'}}
                </div>
            </div>
            <div class = "grid">
                <div class = "col-6" style = "color:black;;font-size: 0.9em;font-weight: 600;">
                    Mano de obra y/o instalación
                </div>
                <div class = "col-6" *ngIf = "calcInstallTotal() > 0; else include"
                style = "display: flex;justify-content: end;color:black;font-size: 0.9em;font-weight: 600;">
                    + {{calcInstallTotal() | currency:'USD':'symbol' : '1.0-5'}}
                </div>
                <ng-template #include>
                    <div class = "col-6"
                        style = "display: flex;justify-content: end;color: var(--verde-zaito-2);font-size: 0.9em;font-weight: 600;">
                            Incluida
                        </div>
                </ng-template>
            </div>
            <div class = "grid" >
                <div class = "col-6" style = "color:black;font-size: 0.9em;font-weight: 600;">
                    Descuento
                </div>
                <div class = "col-6" *ngIf = "totaldiscount > 0"
                style = "color: var(--rojo-zaito-1);display: flex;justify-content: end;font-size: 0.9em;font-weight: 600;">
                    - {{totaldiscount | currency:'USD':'symbol' : '1.0-5'}}
                </div>
                <div class = "col-6">
                    <span  *ngIf="couponDiscount " class = "discountCoupon">
                        <!-- - ${{discountBuyNow | number}} -->
                        <p style="color:  var(--verde-zaito-2);margin-bottom: 5px;font-size: 100%;"> Cupón {{couponDiscount.value}} Aplicado!</p>
                    </span>
                    <span class = "redeemTag"
                    *ngIf="(!couponDiscount)"
                        (click)="showCouponDialog=true">Redimir Cupón</span>
                </div>
            </div>
            <div class = "grid">
                <div class = "col-12 mt-3" *ngIf="!scheduling;else schedulingtemp">
                    <p-button pRipple 
                    [disabled]="!canContinue"
                    (click) = "schedule()"
                    style = "display: flex;height: 7vh;"
                    class="button-continuar">
                        <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
                            <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
                            Agendar
                            </div>
                            <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                                {{calTotal() | currency:'USD':'symbol' : '1.0-5'}}
                            </div>
                        </div>
                    </p-button>
                </div>
                <ng-template #schedulingtemp>
                    <div class = "col-12 mt-3" style = "display: flex;align-items: center;justify-content: center;font-weight: 600;">
                        Confirmando Agendamiento...  <img src = "assets/img/shared/loading.gif" style = "width: 2rem;height: 2rem;" alt="loading">
                    </div>
                    <div class = "col-12" (click) = "cancelConfirmated()" >
                        <p-button class="button-AddCar" [disabled]="disableCancelButton">
                            <span class = "cartbutton">Cancelar</span>
                        </p-button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<p-dialog  [(visible)]="showCouponDialog" 
    [style]="{ width: isMobile ? '90vw' : '25vw' }" [modal]="true" [closable]="false"  [draggable]="false">
    <ng-template pTemplate="header">
        <div class = "grid" style = "width: 100%;margin-bottom: 20px;">
            <div class = "col-10" style = "font-weight: 600;">
                Ingresa el Código de Cupón
            </div>
            <div class="col-2">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="showCouponDialog = false" style="float: right;">cancel</span>
            </div>
        </div>
        
    </ng-template>
    <ng-template pTemplate="content">
        <div class = "p-grid" style = "margin-bottom: 20px;">
            <div class = "col-12">
                <input pInputText [(ngModel)]="couponCode" [class]="'codeCoupon'" 
                [style]="{
                    'width': '100%',
                    'text-align': 'center',
                    'text-transform': 'uppercase',
                    'color': 'var(--azul-zaito-5)',
                    'font-weight': '600'}"/>
            </div>
        </div>
        <div class = "p-grid">
            <div class = "col-12">
                <p-button pRipple class = "button-buyNow mt-2" [style] = "{width: '100%'}" (click) = "apply()">
                    <span style="font-weight: 600;">Aplicar Cupón</span>
                </p-button>
            </div>
        </div>
        
    </ng-template>
    
</p-dialog>