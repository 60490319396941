<app-header></app-header>
<!-- <div class = "grid bannerGrid">
    <div class = "col-12" style = "background-color: black;display: flex;justify-content: center;align-items: center;">
        <img src = "../../../assets/img/shared/Recurso-Banner-Blfrisay-2025-Web.png" class = "bannerImg">
    </div>
</div> -->
<div class="productViewContainer">
    <div class="backgroundCss"></div>
    <div>
        <span class="material-symbols-rounded iconProductView fill font-container" (click)="goBack()" *ngIf = "!isMobile">
            arrow_circle_left
        </span>
    </div>
    <div class="grid grid-nogutter" class="viewProduct">
        <div class="col-10 col-offset-1">
            <div class="grid grid-nogutter">
                <div class = "col-10" *ngIf="isMobile && loaded">
                    <h2 class="titleProduct">{{product.name}}</h2>
                    <h3 class="titleMarkProduct"> {{product.mark}} </h3>
                </div>
                <div class = "col-2" *ngIf="isMobile && loaded">
                    <span class="material-symbols-rounded fill iconDialog selected font-container" (click)="markFavorite();$event.stopPropagation()"
                        *ngIf="loaded && (product?.is_wished?.length > 0) && islogged; else noFavorite" 
                        style="margin-left: 15px; font-size:30px;">favorite
                    </span>
                    <ng-template #noFavorite>
                        <span class="material-symbols-rounded iconDialog font-container" (click)="markFavorite();$event.stopPropagation()"
                            style="margin-left: 15px; font-size:30px;">favorite
                        </span>
                    </ng-template>
                </div>
                <div class="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-5" >
                    <div style="width:100%;display: flex;flex-direction: column;align-items: center;">
                        <div *ngIf="!loaded && !isMobile" class = "gallerySkeletonDImensions">
                            <p-skeleton width="30vw" height="38vh" styleClass="gallerySkeleton"></p-skeleton>
                            <div style="margin-top: 16px; display: flex; gap:3.5vw">
                                <p-skeleton width="6vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                                <p-skeleton width="6vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                                <p-skeleton width="6vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                            </div>
                        </div>
                        <div *ngIf="!loaded && isMobile">
                            <div class="title">
                                <p-skeleton width="55vw" height="48px" styleClass="gallerySkeleton"></p-skeleton>
                            </div>
                        </div>
                        <div *ngIf="!loaded && isMobile" class = "gallerySkeletonDImensions">
                            <p-skeleton width="83vw" height="38vh" styleClass="gallerySkeleton"></p-skeleton>
                            <div style="margin-top: 16px; display: flex; gap:3.5vw">
                                <p-skeleton width="20vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                                <p-skeleton width="20vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                                <p-skeleton width="20vw" height="8vh" styleClass="gallerySkeleton"></p-skeleton>
                            </div>
                        </div>
                        <p-galleria *ngIf="loaded" [numVisible]="3"  [value]="images" [style.width] = "'100%'">
                            <ng-template pTemplate="item" let-item>
                                <div class="galleryImage" (click)="displayBasic = true">
                                    <img [src]="item.previewImageSrc" [alt]="item.alt" [title]="item.title"  class="realImage">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="thumbnail" let-item width="50%" style="background-color: none;">
                                <div class="galleryImage thumb">
                                    <img [src]="item.thumbnailImageSrc" [alt]="item.alt" [title]="item.title"  class="realImage thumb">
                                </div>
                            </ng-template>
                        </p-galleria>
                        <a *ngIf="loaded && product?.video_url !== null" href="{{product?.video_url}}" target="_blank"
                        class = "flex justify-content-center align-items-center mt-2 videobox mb-3">
                            <div>
                                <i class="pi pi-play mr-3 pt-2 pb-2 pl-2 pr-2 ml-5" 
                                style = "border: 1px solid black;border-radius: 3px;">
                                    <img [src]="images[0].previewImageSrc" alt="Video Thumbnail" class="overlay-image">
                                </i> Ver Video del Producto
                            </div>
                        </a>
                        <div *ngIf="loaded"
                        style = "cursor: pointer;" class = "flex justify-content-center align-items-center mt-2">
                            <img  (click)="openModalCarz()" src = "../../../assets/img/shared/chatbubble-ellipses.svg" style = "width: 1.5em;">
                            <p class="questionText" (click)="openModalCarz()">¿Tienes alguna duda sobre {{product?.name}}?</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-5">
                    <div *ngIf="loaded && isMobile">
                        
                        <p *ngIf="product.prize === -1" class="discountPrice service">Cotizar</p>
                        <div class = "grid mb-3 mt-4" *ngIf="product.variants[0]?.cloth_size && haveStock">
                            <div class = "col-12 mb-2" style = "font-weight: 600;font-size: 18px;">
                                Tallas disponibles
                            </div>
                            <div class = "col-2 mt-2 mb-2" *ngFor="let variant of product.variants; index as i"
                            [ngClass]="(i === selectedVariant ? 'selectedVarian' : 'variants') + (variant.stock !== 0 ? '' : ' crossed')"
                            (click) = "selectVariant(i)">
                                {{variant.cloth_size}}
                            </div>
                        </div>
                        <div *ngIf="hasAnyAd > 0" class="containerOriginalPrice">
                            <span *ngIf="product.productAd[0]?.type === 1" style="color: var(--verde-zaito-2); text-decoration: none; font-weight: 600;">
                                {{offer}}%
                            </span>
                            <p *ngIf="product.productAd[0]?.type !== 0 && product.prize !== -1" class="originalPrize">
                                {{originalPrice| currency: 'COP' : '$ ' : '1.0-0'}}
                            </p>
                            <p *ngIf="product.productAd[0]?.type === 0">Promocionado</p>
                        </div>
                        <p *ngIf="product.prize !== -1" class="discountPrice" [ngStyle]="{'margin-top':hasAnyAd > 0 ? 'inherit' : '10px'}" >
                            {{discountPrice | currency : 'COP' : '$ ' : '1.0-0'}}
                        </p>
                        <div #addi>

                        </div>
                        <div class = "paymentBanner mb-2">
                            <img src = "../../../assets/img/shared/Sitecredito-logo-circle_1.png" style = "height: 28px;margin-right: 0.7em;">
                            Paga hasta
                            <span style = "color: var(--azul-zaito-5);font-weight: 500;margin-left: 0.2em;margin-right: 0.2em;">4 cuotas </span>
                            con 
                            <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" 
                            style = "height: 9px;margin-left: 0.2em;margin-right: 0.2em;">
                            <a style = "color:#0033A0; cursor: pointer;font-weight: 500;" class="underlined" (click) = "showSiste()"> +Info</a>
                        </div>
                        <div class = "button-size mb-4 mt-2" *ngIf = "haveStock; else noStockButton" >
                            <p-button class="button-buyNow" (click)="buyNowDialog()" *ngIf="!product.is_service;">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:white;">local_mall</span>
                                <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Comprar Ahora</span>
                            </p-button>
                            <p-button class="button-buyNow" (click)="openSchedule()" *ngIf = "product.need_date">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:white;">calendar_today</span>
                                <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Agendar con Instalación</span>
                            </p-button>
                            <p-button class="button-AddCar" (click)="putInCar()">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:var(--azul-zaito-5);">shopping_cart</span>
                                <span class = "cartbutton">Agregar al Carrito</span>
                            </p-button>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center mr-2">
                                <img src = "../../../assets//img/shared/sync-circle.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Retractos y Devoluciones Gratis!
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 0.8em;font-weight: 500;">
                                            Hasta 5 Días luego de que recibas tu compra.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center mr-2">
                                <img src = "../../../assets/img/shared/navigate-circle.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5);display: flex;align-items: flex-end;">
                                            Enviamos con <img src = "../../../assets/img/shared/footer/CoordinadoraLogo.png" style = "height: 1.1em;">
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 0.8em;font-weight: 500;">
                                            Despachamos el mismo día o al siguiente
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center mr-2">
                                <img src = "../../../assets/img/shared/shield-checkmark.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Compra Segura con Garantía
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 0.8em;font-weight: 500;">
                                            Productos originales, Distribuidores 
                                            <a style="color:var(--azul-zaito-5);cursor: pointer; font-size: 0.9em;" (click) = "showPaymentInfo()">Ver mas...</a>
                                        </p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center mr-2">
                                <img src = "../../../assets/img/shared/card.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Medios de Pago
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <img src = "../../../assets/img/shared/Metodos-de-Pago-3.0.png" style = "height: 2.2em;">
                                </div>
                            </div>
                        </div>
                        <div class = "description" style = "margin-top: 3rem">
                            <h3>Descripción</h3>
                        </div>
                        
                        <div class="textEllipsis" [ngClass] = "{'moreLines': screenHeight >= 1080}" 
                            (click)="sizeDescription > 500 ? openModalDescription() : false" [ngStyle]="{'cursor': sizeDescription > 500 ? 'pointer' : 'default'}">
                            <div *ngFor="let item of subData">
                                <p *ngIf="item[0]" style="font-size:14px;"> <strong>{{ item[0] }}</strong> : {{ item[1]}}</p>
                            </div>
                            <p style="white-space: pre-line; font-size: 14px;"> {{splitDescription[0]}} </p>
                        </div>
                        <p *ngIf="sizeDescription > 500" class="showMoreText" (click)="openModalDescription()">ver más...</p>
                    </div>
                    

                    <div *ngIf="!loaded && !isMobile"  style="width: 100%; display: flex; flex-direction: column; ">
                        <div class="title">
                            <p-skeleton width="20vw" height="48px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div [ngStyle]="{'height': screenHeight >= 1080 ? '33vh' : screenHeight > 900 ? '35vh' : '41vh'}">
                            <p-skeleton width="33vw" height="100%"  styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-top: 15px; margin-bottom:15px">
                            <p-skeleton width="10vw" height="55px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 10px;">
                            <p-skeleton width="18vw" height="50px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <p-skeleton width="18vw" height="50px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                    </div>

                    <div *ngIf="!loaded && isMobile"  style="width: 100%; display: flex; flex-direction: column; ">
                        <div class="title">
                            <p-skeleton width="40vw" height="48px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div [ngStyle]="{'height': screenHeight >= 1080 ? '33vh' : screenHeight > 900 ? '35vh' : '41vh'}">
                            <p-skeleton width="75vw" height="100%"  styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-top: 15px; margin-bottom:15px">
                            <p-skeleton width="30vw" height="55px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 10px;">
                            <p-skeleton width="75vw" height="50px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <p-skeleton width="75vw" height="50px" styleClass="gallerySkeleton"></p-skeleton>
                        </div>
                    </div>
                    
                    <div *ngIf="loaded && !isMobile" >
                        <h2 class="titleProduct">{{product.name}}</h2>
                        <h3 class="titleMarkProduct"> {{product.mark}} </h3>
                        <div class="textEllipsis" [ngClass] = "{'moreLines': screenHeight >= 1080}" 
                            (click)="sizeDescription > 500 ? openModalDescription() : false" [ngStyle]="{'cursor': sizeDescription > 500 ? 'pointer' : 'default'}">
                            <div *ngFor="let item of subData">
                                <p *ngIf="item[0]" style="font-size:14px;"> <strong>{{ item[0] }}</strong> : {{ item[1]}}</p>
                            </div>
                            <p style="white-space: pre-line; font-size: 14px;"> {{splitDescription[0]}} </p>
                        </div>
                        <p *ngIf="sizeDescription > 500" class="showMoreText" (click)="openModalDescription()">ver más...</p>
                        <p *ngIf="product.prize === -1" class="discountPrice service">Cotizar</p>
                        <div class = "grid mb-3 mt-2" *ngIf="product.variants[0]?.cloth_size && haveStock">
                            <div class = "col-12 mb-2" style = "font-weight: 600;font-size:18px">
                                Tallas disponibles
                            </div>
                            <div class = "col-1 mt-2 mb-2" *ngFor="let variant of product.variants; index as i"
                            [ngClass]="(i === selectedVariant ? 'selectedVarian' : 'variants') + (variant.stock !== 0 ? '' : ' crossed')"
                            (click) = "selectVariant(i)">
                                {{variant.cloth_size}}
                            </div>
                        </div>
                        <div *ngIf="hasAnyAd > 0" class="containerOriginalPrice">
                            <span *ngIf="product.productAd[0]?.type === 1" style="color: var(--verde-zaito-2); text-decoration: none; font-weight: 600;"> {{offer}}%</span>
                            <p *ngIf="product.productAd[0]?.type !== 0 && product.prize !== -1" class="originalPrize"> {{originalPrice| currency: 'COP' : '$ ' : '1.0-0'}} </p>
                            <p *ngIf="product.productAd[0]?.type === 0">Promocionado</p>
                        </div>
                        <p *ngIf="product.prize !== -1" class="discountPrice" [ngStyle]="{'margin-top':hasAnyAd > 0 ? 'inherit' : '10px'}" >
                            {{discountPrice | currency : 'COP' : '$ ' : '1.0-0'}}
                        </p>
                        <div #addi class = "mt-2" style = "display: flex;">
                            
                        </div>
                        <div class = "paymentBanner">
                            <img src = "../../../assets/img/shared/Sitecredito-logo-circle_1.png" style = "height: 33px; margin-right: 0.6rem">
                            Paga hasta en 
                            <span style = "color: var(--azul-zaito-5);font-weight: 500;margin-left: 0.2rem;margin-right: 0.2rem;">4 cuotas </span>
                            con 
                            <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" style = "height: 10px;margin-left: 0.2rem;">
                            <a style = "color:#0033A0; cursor: pointer;font-weight: 500;" class="underlined" (click) = "showSiste()"> +Info</a>
                        </div>
                        <div  *ngIf = "haveStock; else noStockButton" class = "mt-4" >
                            <p-button class="button-buyNow" (click)="buyNowDialog()" *ngIf="!product.is_service">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:white;">local_mall</span>
                                <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Comprar Ahora</span>
                            </p-button>
                            <p-button class="button-buyNow" (click)="openSchedule()" *ngIf = "product.need_date">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:white;">calendar_today</span>
                                <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Agendar con Instalación</span>
                            </p-button>
                            <p-button class="button-AddCar" (click)="putInCar()">
                                <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:var(--azul-zaito-5);">shopping_cart</span>
                                <span class = "cartbutton">Agregar al Carrito</span>
                            </p-button>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center">
                                    <!-- <i class="pi pi-sync"></i> -->
                                <img src = "../../../assets//img/shared/sync-circle.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Retractos y Devoluciones Gratis!
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 1em;font-weight: 500;">
                                            Hasta 5 Días luego de que recibas tu compra.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center">
                                <img src = "../../../assets/img/shared/navigate-circle.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5); display: flex;align-items: flex-end;">
                                            Enviamos con <img src = "../../../assets/img/shared/footer/CoordinadoraLogo.png" style = "height: 1.2em;">
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 1em;font-weight: 500;">
                                            Despachamos el mismo día o al siguiente
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center">
                                <img src = "../../../assets/img/shared/shield-checkmark.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Compra Segura con Garantía
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "color:var(--gris-zaito-5);font-size: 1em;font-weight: 500;">
                                            Productos originales, Distribuidores 
                                            <a style="color:var(--azul-zaito-5);cursor: pointer; font-size: 0.9em;"  (click) = "showPaymentInfo()">Ver mas...</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class = "mt-4 grid">
                            <div class = "col-1 flex justify-content-center">
                                <img src = "../../../assets/img/shared/card.svg" style = "width: 1.8em;">
                            </div>
                            <div class = "col">
                                <div class = "grid">
                                    <div class = "col-12">
                                        <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                                            Medios de Pago
                                        </p>
                                    </div>
                                </div>
                                <div class = "grid">
                                    <div class = "grid">
                                        <img src = "../../../assets/img/shared/Metodos-de-Pago-3.0.png" style = "height: 2.8em;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col" *ngIf = "!isMobile">
                    <span class="material-symbols-rounded fill iconDialog selected font-container" (click)="markFavorite();$event.stopPropagation()"
                        *ngIf="loaded && (product?.is_wished?.length > 0) && islogged; else noFavorite" 
                        style="margin-left: 15px; font-size:30px;">favorite
                    </span>
                    <ng-template #noFavorite>
                        <span class="material-symbols-rounded iconDialog font-container" (click)="markFavorite();$event.stopPropagation()"
                            style="margin-left: 15px; font-size:30px;">favorite
                        </span>
                    </ng-template>
                </div>

            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="displayModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [closable]="false" class="dialogProductDescription">
    <ng-template pTemplate="header">
        <!-- <div class="col-2"></div> -->
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-end;">
            <h2 class="titleProduct">{{product.name}}</h2>
            <span class="material-symbols-rounded iconDialogDescription fill font-container" (click)="dissmissModal()">cancel</span>
        </div>
        <h3 class="titleMarkProduct" style="font-weight: 600!important; margin-bottom: 0!important"> {{product.mark}} </h3>
    </ng-template>
    <div *ngIf="loaded">
        <div *ngFor="let item of subData">
            <p style="font-size:14px;" *ngIf="item[0]"> <strong>{{ item[0] }}</strong> : {{ item[1]}}</p>
        </div>
        <p class="descriptionModal"> {{splitDescription[0]}} </p>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayDialogBuyNow" [modal]="true" [closable]="true" (onShow)="onShowDialogBuyNow()" class="dialogBuyNow">
    <ng-container *ngIf="!showSkeleton; else skeleton">
        <div class="col-12 p-3">
            <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="displayDialogBuyNow = false">cancel</span>
        </div>
        <div class="col-12 flex nameAndImage">
            <div class="col-4" style="height: 100%;">
                <div class="z-shadow-principal card-image-product flex" style="height: 70%;">
                    <img [src]="product?.id_image[0]?.image_idimage?.url" class="image-product" alt="{{ product?.name }}"
                          onerror="this.src='assets/img/shared/imagenotavailable.svg'" style="width: 100%; height: 100%; border-radius: 8px;">
                </div>
            </div>
            <div class="col-4 pl-2">
                <div class="card-content">
                    <label class="ellipsis" pTooltip="{{product?.name}}"> {{ product?.name }} {{ '- ' + variantName}}</label>
                    <div>
                        <div *ngIf="product?.productAd?.length == 0">
                            <p style="margin: 0;">
                                <label *ngIf="product?.prize !== -1" class="item-number">$ {{ originalPrice | number }}</label>
                                <label *ngIf="product?.prize === -1" class="item-number">Cotizar</label>
                            </p>
                        </div>
                        <div *ngIf="product?.productAd[0]?.type == 0" style="margin-top: -7px;">
                            <p style="margin: 0;">
                                <label style="color: var(--verde-zaito-2);" class="text-ad">Promocionado </label>
                            </p>
                            <label *ngIf="product?.prize !== -1" class="item-number">$ {{ originalPrice | number }}</label>
                            <label *ngIf="product?.prize === -1" class="item-number">Cotizar</label>
                        </div>
                        <div *ngIf="product?.productAd[0]?.type == 2">
                            <p style="margin: 0;">
                                <label style="color: var(--verde-zaito-2);" class="text-ad">Antes </label>
                                <label class="text-ad text-antes">$ {{originalPrice | number}}</label>
                            </p>
                            <div class="item-number">$ {{ discountPrice | number }}</div>
                        </div>
                        <div *ngIf="product?.productAd[0]?.type == 1">
                            <p style="margin: 0;">
                                <label style="color: var(--verde-zaito-2);" class="text-ad">-{{ product.productAd[0].discount }}%</label>
                                <label class="text-ad text-antes">$ {{originalPrice | number}}</label>
                            </p>
                            <div class="item-number">$ {{ discountPrice | number }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 flex" style="flex-wrap: wrap;">
                <div class="flex units">
                    <span class="material-symbols-rounded fill font-container" *ngIf="units > 1" (click)="changeUnits('subs')">do_not_disturb_on</span>
                    <div style = "display: flex;align-items: center;text-align: center;">{{ units }} {{units > 1 ? 'unds' : 'und'}}</div>
                    <span class="material-symbols-rounded fill font-container" *ngIf="units < (selectedVariant ? product?.variants[selectedVariant].stock : product?.stock!)" (click)="changeUnits('add')">add_circle</span>
                </div>
            </div>
        </div>
        <div class="col-12" style="height: 1px; background-color: var(--gris-zaito-2);"></div>
    </ng-container>
    <ng-container *ngIf="!showSkeletonPricesInfo; else skeletonPricesInfo">
        <div class = "col-12 mb-3" style = "color: black;font-weight: 600;padding: 0 10%">
            Elije la forma de entrega
        </div>
        <div class = "col-12 mb-3" style = "display: flex;flex-direction: column;padding: 0 10%;">
            <div class="flex align-items-center mb-3" style = "font-size: 15px;font-weight: 500;" *ngIf="product?.delivery">
                <p-radioButton name="envios" value=1 (click)="changeType(1)" 
                [(ngModel)]="type" inputId="envios" ></p-radioButton>
                <label for="ingredient1" class="ml-2" [style.font-weight] = "type == 1 ? '600' : '500'"
                [style.color] = "type == 1 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-5)'">
                    Enviar a domicilio.
                </label>
            </div>
            
            <div class="flex align-items-center  mb-3 " style = "font-size: 15px;font-weight: 500;" *ngIf="product?.get_in_workship">
                <p-radioButton name="retiros" value=0 (click)="changeType(0)"
                [(ngModel)]="type" inputId="retiros" ></p-radioButton>
                <label for="ingredient2" class="ml-2" [style.font-weight] = "type == 0 ? '600' : '500'"
                [style.color] = "type == 0 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-5)'">
                    Retirar en un punto de entrega.
                </label>
            </div>

            <div class="flex align-items-center " style = "font-size: 15px;font-weight: 500;" *ngIf="product?.need_date">
                <p-radioButton name="citas" value=2 (click)="changeType(2)" [disabled]="!isfiltered"
                [(ngModel)]="type" inputId="citas"></p-radioButton>
                <label for="ingredient3" class="ml-2" [style.font-weight] = "type == 2 ? '600' : '500'"
                [style.color] = "type == 2 ? 'var(--azul-zaito-5)' : 'var(--gris-zaito-5)'">
                    Con instalación en nuestro taller.
                </label>
            </div>
            <div class ="flex align-items-center">
                <p *ngIf = "!isfiltered">*Esta opción no está disponible porque la función 'Filtrar por Vehículo Principal' está desactivada.</p>   
            </div> 
        </div>
        <div class="col-12 flex mb-3" style="padding: 0 10%;" *ngIf = "type == 1">
            <div class="col-10 container-address">
                <p class="m-0 mb-1" style="font-weight: 600;">Dirección de Envíos</p>
                <p class="m-0">{{ currentAddress }}</p>
            </div>
            <div class="col-2 flex justify-content-center">
                <span class="material-symbols-rounded fill icon-close cursor-pointer font-container" style = "color: var(--gris-zaito-4);"
                (click)="openDialogDirection()">
                    edit
                </span>
            </div>
        </div>
        <div *ngIf="!shippingHasInfo && type == 1" class="no-coverage col-12 mb-2">
            <p style="font-weight: 500; font-size: 0.7rem; text-align: center; padding-bottom: 0px;margin-bottom: 0px;">
                *Sin cobertura de envíos para tu dirección actual
            </p>
        </div>
        <div class = "col-12 mb-3" *ngIf="type==0">
            <div class = "grid" style = "font-size: 80%;">
                <div class = "col-12 flex justify-content-center" style = "margin-bottom: 0;padding-bottom: 0;color:black;text-align: center;">
                    <i>*Disponible solo para entrega <br *ngIf="isMobile"> en la sede de Itagüí Antioquia.</i>
                </div>
                <div class = "col-12 flex justify-content-center" style = "margin-top: 0;padding-top: 0;color:black;text-align: center;">
                    <i> Horarios: Lunes a Viernes de 8 am - 5pm, <br> 
                        Sabados 8 am - 12 pm
                    </i>
                </div>
            </div>
        </div>
        <a *ngIf="type==0 || type == 2"  href="https://www.google.com/maps/place/Zaito/@6.1788948,-75.5960059,17z/data=!3m1!4b1!4m6!3m5!1s0x8e4682803c811f6d:0x8a26fa94e8f49c2f!8m2!3d6.1788948!4d-75.593431!16s%2Fg%2F11rylmqnrc?hl=es-419&entry=ttu" target="_blank">
            <div class = "col-12 mb-2" >
                <div class = "grid" style = "width: 100%;display: flex;justify-content: center;">
                    <div class = "col-9" style = "border: 2px solid var(--gris-primeng);border-radius: 6px;">
                        <div class = "grid">
                            <div class = "col-9 flex align-items-center"
                            style = "font-size: 0.7em;text-align: center;padding-left: 10px;color:var(--azul-zaito-5);font-weight: 500;">
                                Carrera 42 # 67a -191 interior 114-2<br>
                                Centro Empresarial Capricentro <br *ngIf="isMobile"> Itagüi-Antioquia
                            </div>
                            <div class = "col-3 flex align-items-center justify-content-center">
                                <img style = "width: 70%; height: 70%;"
                                src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Google_Maps_Logo_2020.svg/512px-Google_Maps_Logo_2020.svg.png">
                            </div>  
                        </div>
                    </div>
                   
                </div>
            </div>
        </a>
        
        <div class="col-12 flex container-total mb-4" style="padding: 0 10%;font-family: var(--z-primary-font-family);">
            <div class="col-4">
                <p class="m-0">Subtotal</p>
            </div>
            <div class="col-8" style = "display: flex;justify-content: flex-end;">
                <p class="m-0">+ ${{ subTotal | number }}</p>
            </div>
            <div class="col-4" *ngIf="type==1">
                <p class="m-0">Costo Envío</p>
            </div>
            <div class="col-8" style = "display: flex;justify-content: flex-end;" *ngIf="type==1">
                <p class="m-0" *ngIf="deliveryPrice > 0;else freeDel"> + ${{ deliveryPrice | number }}</p>
                <ng-template #freeDel>
                    <p style="color:  var(--verde-zaito-2)">+Envio Gratis </p>
                </ng-template>
            </div>
            <div class="col-5" *ngIf="type==2">
                <p class="m-0">Mano de obra y/o instalación</p>
            </div>
            <div class="col-7" style = "display: flex;justify-content: flex-end;" *ngIf="type==2">
                <p class="m-0" *ngIf="installPrice > 0;else include"> + ${{ installPrice | number }}</p>
                <ng-template #include>
                    <p class="m-0" style = "color: var(--verde-zaito-2);"> Incluida</p>
                </ng-template>
            </div>
            <div class="col-4">
                <p class="m-0">Descuento</p>
            </div>
            <div class="col-8" style = "display: flex;align-items: flex-end;justify-content: flex-end;flex-direction: column;">
                <p class="m-0" style="color: var(--rojo-zaito-1);" *ngIf="discountBuyNow != 0 && !couponDelivery">
                    - {{ discountBuyNow | number }}
                </p>
                <span  *ngIf="couponDiscount " class = "discountCoupon">
                    <!-- - ${{discountBuyNow | number}} -->
                    <p style="color:  var(--verde-zaito-2);margin-bottom: 5px;font-size: 100%;"> Cupón {{couponDiscount.value}} Aplicado!</p>
                </span>
                <span style="color:  var(--verde-zaito-2);display: flex;flex-direction: column;align-items: flex-end;"
                    *ngIf="couponDelivery">
                    <p style="color:  var(--verde-zaito-2);margin-bottom: 5px;">Cupón {{couponDelivery.value}} Aplicado! </p>
                </span>
                <span class = "redeemTag"
                *ngIf="(!couponDelivery || !couponDiscount)"
                    (click)="showCouponDialog=true">Redimir Cupón</span>
            </div>
        </div>
        
        <div class="separated-gray-line"></div>
        <div class ="col-12 paymentAdvice mt-1" *ngIf="type == 2;">
            *El pago total se realiza al final de la cita; en Zaito el cumplimiento
            es primordial para garantizarte un excelente servicio
        </div>
        <div class ="col-12" style = "display: flex;justify-content: center;" *ngIf="type == 2">
            <img src = "../../../assets/img/shared/Metodos-de-Pago-3.0-Citas.png" style = "width: 60%;">
        </div>
        <div class="col-12 p-4 m-0" style = "padding-top: 1rem !important;">
            <p-button pRipple
            (onClick)="this.type != 2 ? this.paymentDialog = true : openSchedule()"
            [disabled]="(!shippingHasInfo && type === 1) || (actualAmountVehicles === 0 && type == 2) || continueLoading"
            class="button-continuar">
                <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
                    <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
                        {{this.type != 2 ? 'Continuar' : 'Ir a Agendar'}}
                    </div>
                    <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                        {{(total | number)}}
                    </div>
                </div>
            </p-button>
        </div>
    </ng-container>
    
    <ng-template #skeleton>
        <app-skeleton-text [value]="4"></app-skeleton-text>
    </ng-template>
    <ng-template #skeletonPricesInfo>
        <app-skeleton-text [value]="44"></app-skeleton-text>
    </ng-template>
    <!-- <ng-template pTemplate="footer">
        
    </ng-template> -->
</p-dialog>

<p-galleria *ngIf="loaded" [value]="images" [(visible)]="displayBasic" [responsiveOptions]="responsiveOptions"  [containerStyle]="{'max-width': '80%'}"
    [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="!isMobile" [showIndicators]="isMobile">
    <ng-template pTemplate="item" let-item>
        <div class="galleryImage fullImage">
            <img [src]="item.previewImageSrc" [alt]="item.alt" [title]="item.title"  class="realImage fullImage">
        </div>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item width="50%">
        <div class="galleryImage thumb">
            <img [src]="item.thumbnailImageSrc" [alt]="item.alt" [title]="item.title"  class="realImage thumb">
        </div>
    </ng-template>
</p-galleria>

<p-dialog [(visible)]="showDialogAddress" class="dialog-addresses" [blockScroll]="true" [modal]="true" [closable]="false">
    <ng-template pTemplate="header">
        <div class="col-12 flex" style="padding: 4% 6%;">
            <div class="col-6 p-0">
                <div class="title-dialogs">Dirección</div>
            </div>
            <div class="col-6 flex p-0" style="justify-content: flex-end">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="showDialogAddress = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngIf="!showSkeletonAddress; else skeletonAddress">
            <div class="col-12 flex mb-2" *ngFor="let address of addresses" style="padding: 0 8%; cursor: pointer;" (click)="changeAddress(address.id)">
                <div class="col-1">
                    <p-radioButton [value]="address.current" [(ngModel)]="select"></p-radioButton>
                </div>
                <div class="col-11">
                    <div style="color: black; font-family: var(--z-primary-font-family); font-size: 95%;">{{ address.address}}</div>
                    <div style="color: var(--gris-zaito-4); font-family: var(--z-primary-font-family); font-size: 85%;">{{ address.description }}</div>
                </div>
            </div>
            <div class="col-12 flex" style="justify-content: center;">
                <span class="material-symbols-rounded icon fill font-container" 
                style="color: var(--gris-zaito-4); font-size: 2rem;" (click)="openDialogCreateAddress()">add_circle</span>
            </div>
        </ng-container>
        <ng-template #skeletonAddress>
            <app-skeleton-text [value]="1"></app-skeleton-text>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
    <ng-template #skeletonAddress>
        <app-skeleton-text [value]="1"></app-skeleton-text>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="sistecreditDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}">
    <div class = "container">
        <div class = "grid ">
            <div class = "col-12 p-3">
                <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" style = "height: 50%;" >
            </div>
        </div>
        <div class = "grid">
            <div class = "col-12 p-3" style="font-weight: 600;padding-top: 0 !important;">
                Digita tu número de documento para continuar.
            </div>
        </div>
        <div class = "grid" style = "padding-top: 0.5rem !important;">
            <div class = "col-12" style = "padding: 0.5rem;">
                <p-dropdown [options]="idTypes" optionLabel="value" placeholder="Tipo de documento" class="dropdown-select"  
                [(ngModel)]="idType">
                </p-dropdown>
            </div>
            <div class = "col-12" style = "padding: 0.5rem;" >
                <input [(ngModel)]="idnumber"  pInputText style = "padding:0.75rem 0.75rem; width: 100%;border: 1px solid" >
            </div>
            <div class = "col-12" style = "padding: 0.5rem;" style = "margin-top: 1rem !important;">
                <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber?.length > 0) || !idType"
                [style] = "{width: '100%'}" (click) = "buySiste()" *ngIf = "!loadingSiste;else sisLoading">
                    <span style="font-weight: 600;">Continuar</span>
                </p-button>
                <ng-template #sisLoading>
                    <div class = "col-12 flex justify-content-center" >
                        <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                    </div>
                  </ng-template>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="addiDialog" [modal]="true" [style]="{ width: isMobile ? '90vw' : '20vw'}">
    <div class = "container">
        <div class = "grid">
            <div class = "col-12 mb-2">
                <img src="../../../assets/img/shared/Addi-logo-zai.png">
            </div>
        </div>
        <div class = "grid">
            <div class = "col-12 p-3" style="font-weight: 600;padding-top: 0 !important;">
                Digita tu número de documento y correo electrónico para continuar.
            </div>
        </div>
        <div class = "grid" style = "padding-top: 0.5rem !important;">
            <!-- <div class = "col-12" style = "padding: 0.5rem;">
                <p-dropdown [options]="idTypes" optionLabel="value" placeholder="Tipo de documento" class="dropdown-select"  
                [(ngModel)]="idType">
                </p-dropdown>
            </div> -->
            <div class = "col-12" style = "padding: 0.5rem;" >
                <p-inputNumber [(ngModel)]="idnumber" placeholder="Numero de Documento"  [useGrouping]="false" style = "width: 100%;" />
            </div>
            <div class = "col-12" style = "padding: 0.5rem;">
                <input pInputText placeholder="Correo Electrónico" style = "padding:0.75rem 0.75rem; width: 100%;border: 1px solid"  [(ngModel)]="email" />
            </div>
        </div>
        <div class = grid>
            <div class = "col-12" style = "padding: 0.5rem;" style = "margin-top: 1rem !important;">
                <p-button pRipple class = "button-buyNow mt-2" [disabled]="!(idnumber > 0) || !email"
                [style] = "{width: '100%'}" (click) = "(idnumber> 0) && email ? buyAddi(): ''" *ngIf = "!loadingaddi;else addiLoading">
                    <span style="font-weight: 600;">Continuar</span>
                </p-button>
                <ng-template #addiLoading>
                    <div class = "col-12 flex justify-content-center" >
                        <img src = "assets/img/shared/loading.gif" style = "width: 3rem;height: 3rem;" alt="loading">
                    </div>
                  </ng-template>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showDialogCreateAddress" class="dialog-create-addresses" [blockScroll]="true" [modal]="true" [closable]="false" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="col-12 flex" style="padding: 4% 6%;">
            <div class="col-6 p-0">
                <div class="title-dialogs">Crear Dirección</div>
            </div>
            <div class="col-6 p-0 flex" style="justify-content: flex-end">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="closeDialogCreateAddress()">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <app-create-direction (createAddress)="emitCreateAddress($event)"></app-create-direction>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>

<app-recommendeds></app-recommendeds>
<app-support-carz style="z-index: 2;"></app-support-carz>
<app-footer-web></app-footer-web>


<ng-template #noStockButton>
    <div class = "button-size">
        <p-button class="button-rejected" [disabled]="true">
            <span class="material-symbols-outlined font-container" style="font-size: 28px;">
                schedule
            </span>
            <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Agotado</span>
        </p-button>
    </div>
</ng-template>

<p-dialog  [(visible)]="showCouponDialog" 
    [style]="{ width: isMobile ? '90vw' : '25vw' }" [modal]="true" [closable]="false"  [draggable]="false">
    <ng-template pTemplate="header">
        <div class = "grid" style = "width: 100%;margin-bottom: 20px;">
            <div class = "col-10" style = "font-weight: 600;">
                Ingresa el Código de Cupón
            </div>
            <div class="col-2">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="showCouponDialog = false" style="float: right;">cancel</span>
            </div>
        </div>
        
    </ng-template>
    <ng-template pTemplate="content">
        <div class = "p-grid" style = "margin-bottom: 20px;">
            <div class = "col-12">
                <input pInputText [(ngModel)]="couponCode" [class]="'codeCoupon'" 
                [style]="{
                    'width': '100%',
                    'text-align': 'center',
                    'text-transform': 'uppercase',
                    'color': 'var(--azul-zaito-5)',
                    'font-weight': '600'}"/>
            </div>
        </div>
        <div class = "p-grid">
            <div class = "col-12">
                <p-button pRipple class = "button-buyNow mt-2" [style] = "{width: '100%'}" (click) = "apply()">
                    <span style="font-weight: 600;">Aplicar Cupón</span>
                </p-button>
            </div>
        </div>
        
    </ng-template>
    
</p-dialog>

<p-dialog [(visible)]="paymentDialog" [modal]="true" [closable] = "true" [showHeader]="false"
    [style]="{ width: isMobile ? '90vw' : '30vw'}" [dismissableMask]="true" class = "dialogPayment">
    <div class = "container">
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 p-3" style="font-weight: 600;color:black">
                Selecciona el Medio de Pago
            </div>
        </div>
        <div class = "horizontalLine">

        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center" 
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'epayco'">
                <p-radioButton name="methods" value="epayco" [(ngModel)]="paymentMethod"></p-radioButton>
                <img src = "../../../assets/img/shared/Medios-pagos-ns.png"  style = "height: 2em;" class = "ml-2">
            </div>
        </div>
        <div class = "horizontalLine mb-2">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12 flex align-items-center" 
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;font-size: 12px;" 
            (click) = "paymentMethod = 'sistecredit'">
                <p-radioButton name="methods" value="sistecredit" class = "mr-2" [(ngModel)]="paymentMethod"></p-radioButton>
                <!-- <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" class = "ml-2"
                            style = "height: 0.9em;">
                <div  style = "text-align: center; font-size: 0.9em;" class = "ml-3">
                    Ir a pagar hasta  en 
                    <span style = "color: var(--azul-zaito-5); font-weight: bold; display: inline;">4 cuotas </span>
                </div> -->
                <div class="paymentBanner bannerPaying">
                    <img src = "../../../assets/img/shared/Sitecredito-logo-circle_1.png"  class = "mr-2"  style = "height: 30px;">
                    Paga hasta <div *ngIf ="!isMobile" style = "margin-left: 0.4em">en </div> 
                    <span style = "color: var(--azul-zaito-5);font-weight: 500;margin-left: 0.2em;margin-right: 0.2em;">4 cuotas </span>
                    con 
                    <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" 
                    style = "height: 10px;margin-left: 0.2em;margin-right: 0.2em;">
                </div>
            </div>
        </div>
        <div class = "horizontalLine ">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;" *ngIf = "allow_addi">
            <div class = "col-12 flex align-items-center" 
            style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;" 
            (click) = "paymentMethod = 'addi'">
                <p-radioButton name="methods" value="addi" [(ngModel)]="paymentMethod"></p-radioButton>
                <addi-widget price="{{discountPrice}}" ally-slug="zaito-ecommerce" class = "ml-2"></addi-widget>
            </div>
        </div>
        <div class = "horizontalLine" *ngIf = "allow_addi">
        </div>
        <div class = "grid  mt-3  mb-3" style = "padding-left: 2rem;padding-right: 2rem;" *ngIf = "type == 1">
            <div class = "col-12 flex align-items-center"  *ngIf = "roundSubTotal >= 90000;else nonContra"
            style = "padding-top: 0px;padding-bottom: 0px;;cursor: pointer;" 
            (click) = "paymentMethod = 'contraentrega'">
                <p-radioButton name="methods" value="contraentrega" [(ngModel)]="paymentMethod"></p-radioButton>
                <img src = "../../../assets/img/shared/ContraEntregaCoordinadora.png" class = "ml-3 contraImg" >
            </div>
            <ng-template #nonContra>
                <div class = "col-12 flex align-items-center" 
                    style = "padding-top: 0px;padding-bottom: 0px;cursor: pointer;">
                        <p-radioButton name="methods" value="contraentrega" [disabled]="true" [(ngModel)]="paymentMethod"></p-radioButton>
                        <img src = "../../../assets/img/shared/MinContraEntregaCoordinadoraX2.png" class = "ml-3 paymentOption" >
                    </div>
            </ng-template>
        </div>
        <div class = "horizontalLine" *ngIf = "type == 1">
        </div>
        <div class = "grid mt-3 mb-3" style = "padding-left: 2rem;padding-right: 2rem;">
            <div class = "col-12">
                <p-button pRipple
                (onClick)="toPayment()"
                [disabled]="this.paymentMethod === undefined" 
                class="button-continuar">
                    <div class = "grid" style = "width: 100%;margin-top: 0.05em;font-size: 1.1rem;font-weight: 600;">
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: start;">
                          Ir a pagar
                        </div>
                        <div class = "col-6" style = "display: flex; align-items: center;justify-content: end;">
                            {{(total | number)}}
                        </div>
                    </div>
                </p-button>
            </div>
            
        </div>
    </div>
</p-dialog>


<p-dialog  [(visible)]="noVehiclesToDateModal" 
    [style]="{ width: isMobile ? '90vw' : '30vw'}" [modal]="true" [closable]="true"  [draggable]="false">
    <ng-template pTemplate="content">
        <div class = "grid"  *ngIf = "islogged;else nolog">
            <div class = "col-12" style = "text-align: center;font-weight: 600;color:var(--azul-zaito-5)">
                Para Agendar una cita necesitas una Moto <br>
                Creada para ver compatibilidad del producto <br>
                o servicio que te ofrecemos para brindarte la 
                mejor experiencia
            </div>
            <div class = "col-12 ">
                <div class = "grid justify-content-center flex mt-2">
                    <div class = "col-4">
                        <div class = "grid z-shadow-principal button-sidebar" 
                        (click) = "noVehiclesToDateModal = false"
                        [routerLink]="['/home']"
                        style = "cursor: pointer;display: flex;justify-content: center;align-items: center;text-align: center;">
                            <div class = "col-6" style = "padding: 5px;">
                                <img src="assets/img/home/MotoIcon.svg" alt="LOgo Moto">
                            </div>
                            <div class = "col-6" style = "padding: 5px;">
                                <span class="material-symbols-rounded icon fill font-container">add_circle</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #nolog>
            <div class = "grid">
                <div class = "col-12" style = "text-align: center;font-weight: 600;color:var(--azul-zaito-5)">
                    Para agendar tu cita necesitas estar registrado en ZAITO.<br>
                    Esto nos permite ofrecerte una experiencia personalizada y eficiente.
 
                </div>
                <div class = "col-12" style = "text-align: center;font-weight: 600;color:var(--azul-zaito-5)">
                    <p-button class="button-buyNow mt-3" (click) ="toRegister()">
                        <span class="material-symbols-rounded fill font-container" style="font-size: 28px; color:white;"></span>
                        <span style="font-size: 14px!important; margin-left: 10px; font-weight: 500;">Ir a Registrarme</span>
                    </p-button>
                </div>
            </div>
        </ng-template>
        
    </ng-template>
    
</p-dialog>


