import { Injectable } from '@angular/core';
import { doc, Firestore, setDoc, deleteDoc, getDoc } from '@angular/fire/firestore';
import { GeneralConfigurationService } from '../shared/configuration/general-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class MetaSellService {

  _fbp: string|undefined

  constructor(private firestore: Firestore, 
    private generalConfigurationService: GeneralConfigurationService,) {
    this._fbp = this.generalConfigurationService.getCookie('_fbp');
  }

  async saveSell(idsventa: any){
    const userDocRef = doc(this.firestore, `metaSells/${idsventa}`)
    let data = {
      fbp: this._fbp,
      ids: idsventa,
    }
    return setDoc(userDocRef, data, {merge: true});
  }
}
