<div class="grid grid-nogutter" style="margin-bottom: 0; height:15vh">
  <div class="div col-8 col-offset-2" style="display: flex; justify-content: center; align-items: center;text-align: center;">
    <h2>{{tittle}}</h2>
  </div>
  <div class="div col-2" style="display: flex; justify-content: center; align-items: center;">
    <span class="material-symbols-rounded icon iconSize"  (click)="download()">download</span>
  </div>
</div>



<div style="display: flex; justify-content: center;">
  <iframe [src]="pdfUrl()" width="800" height="700" frameborder="0"
    webkitallowfullscreen mozallowfullscreen allowfullscreen>
  </iframe>
  <!-- <iframe src="{{src}}" style="width:600px; height:500px;" frameborder="0"></iframe> -->
  <!-- <iframe src="{{src}}" style="width:600px; height:500px;"></iframe> -->
    <!-- <div class = "pdf" *ngIf = "src">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]= zoom
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width:100%; height:100%"
      ></pdf-viewer> -->
    <!-- </div>  --> 
</div>