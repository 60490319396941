import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { doc, Firestore, setDoc, deleteDoc, collection, docData} from '@angular/fire/firestore';
// import { TokenService } from "../shared/token.service";
// import { AngularFirestore } from "@angular/fire/firestore";
// import { NotificationService } from "../notifications/notification.service";

@Injectable({
  providedIn: "root"
})
export class BuysService {

  sellId = 0;
  baseurl = environment.server_url;
  token;
  httpHeaders;
  filterBuys: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private waitingSells!: Observable<any>;

  constructor(private http: HttpClient,
    private firestore: Firestore, 
            ) {
    this.token = localStorage.getItem("token");
    this.httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });
  }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }


  // createWaitingSell() {
  //   this.waitingSells = this.aFirestore.collection("sells", ref => 
  //     ref.where('user_id', '==', this.notificationService.userId).where('wait', '==', true)).valueChanges();
  // }

  getWaitingSells(): Observable<any> {
    return this.waitingSells;
  }

  getBuys( page: number, filter?: number ): Promise<any> {
    return this.http.get(this.baseurl + "payment/follow/?page=" + page + "&all_user=" + filter, { headers: this.httpHeaders }).toPromise();
  }

  getProductBuy( id: number ): Promise<any>{
    let onlyProduct = 1;
    return this.http.get(this.baseurl + "payment/follow/" + id + "/?onlyProduct=" + onlyProduct, { headers: this.httpHeaders }).toPromise();
  }

  getDateOfProductBuy(id: number): Promise<any> {
    return this.http.get(this.baseurl + "payment/info_chain/" + id + "/", { headers: this.httpHeaders }).toPromise();
  }

  nextStep(id: number): Promise<any>{
    const body = {
      sell: id
    }
    return this.http.post<any>(this.baseurl + "payment/next_step/", body , { headers: this.httpHeaders }).toPromise();
  }

  hideBuy(id : number): Promise<any> {
    const body = {
      hidden_for_used: true
    }
    return this.http.patch<any>(this.baseurl + "payment/follow/" + id + "/", body , { headers: this.httpHeaders }).toPromise();
  }

  setActualStep(value: number, type: number): number {
    let progress: number;
    switch (value) {
      case 0:
        progress = type === 2 ? 0.1 : 0.1; 
        break;
      case 1:
        progress = type === 2 ? 0.1 : 0.1;
        break;
      case 2:
        progress = type === 2 ? 0.4 : 0.55;
        break;
      case 3:
        progress = type === 2 ? 0.7 : 1;
        break;
      case 4:
        progress = type === 2 ? 1 : 0.1;
        break;
      default:
        progress = type === 2 ? 0.1 : 0.1;
        break;
    }
    return progress;
  }

  getPercentages(id : number, dane: string): Promise<any> {
    this.setToken();
    const url = "shop/getPercentage/?product=" + id + "&delivery=1" + "&dane=" + dane
    return this.http.get<any>(this.baseurl + url , { headers: this.httpHeaders }).toPromise();
  }

  getPercentage(id : number): Promise<any> {
    this.setToken();
    const url = "shop/getPercentageSet/" + id + "/";
    return this.http.get<any>(this.baseurl + url , { headers: this.httpHeaders }).toPromise();
  }

  getPercentageWithPrice(ids: number[], subTotal:number, dane: string): Promise<any> {
    const body = {ids, dane_code: dane, delivery: true};
    return this.http.post<any>(this.baseurl + "shop/getPercentageCart/?subTotal=" + subTotal, body, { headers: this.httpHeaders }).toPromise();
  }

  getPaymentState(ref: string): Observable<any>{
    const epaycolink = 'https://sharedzaitofunctions.azurewebsites.net/api/getPaymentState?code=ay8JzgxOxA12DyJERfYGfgXQhdWROVSyIsA-ZuWSsbv-AzFueYNjoA==';
    const body = {ref}
    return this.http.post<any>(epaycolink,body, {headers: this.httpHeaders});
  }

  checkSell(id: number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'payment/checkSell' + id , { headers: this.httpHeaders });
  }

  getSisteLink(body: any): Observable<any>{
    const headers = new HttpHeaders({ 
      "Content-Type": "application/json",
      SCLocation: "0.0",
      country: "co",
      SCOrigen: "Production",
      "Ocp-Apim-Subscription-Key": environment.Ocp});
    const sisteLink = 'https://api.credinet.co/paymentpublic/StartCredit';
    return this.http.post<any>(sisteLink, body, {headers});
  }

  async saveSell(ids: any, body: any){
    const userDocRef = doc(this.firestore, `sells/${ids}`)
    await setDoc(userDocRef, body, {merge: true});
  }

  getFireSell(ids: any): Observable<any>{
    const docref =  doc(this.firestore, `sells/${ids}`);
    return docData(docref) as Observable<any>;
  }


}
