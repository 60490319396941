import { Component, Inject, Renderer2, HostListener, ElementRef, ViewChild } from '@angular/core';
import { ShopService } from 'src/app/services/shop/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CarzService } from 'src/app/services/carz/carz.service';
import { DeliveryService } from '../../services/shop/delivery.service';
import { DOCUMENT } from '@angular/common';
import { Product, Xml } from '../../interfaces/shop';
import { environment } from 'src/environments/environment';
import { ShopCartService } from 'src/app/services/shop/shop-cart.service';
import { Location } from "@angular/common";
import { MessageService } from "primeng/api";
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service';
import { BuysService } from 'src/app/services/buys/buys.service';
import { FavoriteService } from 'src/app/services/favorite/favorite.service';
import { AlliesService } from 'src/app/services/allies/allies.service';
import { PackageDimensions, Sell, SellLine, ShopCar, CheckoutValues, ShopCarSells } from 'src/app/interfaces/shopCart';
import { HttpErrorResponse } from "@angular/common/http";
import { DeliveryObject, ShippingObject } from 'src/app/interfaces/shipping';
import { AccountService } from 'src/app/services/shared/account/account.service';
import { GarageService } from 'src/app/services/garage/garage.service';
import { AddressService } from 'src/app/services/user/address/address.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { CheckoutService } from 'src/app/services/buys/checkout.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CouponsService } from 'src/app/services/coupons/coupons.service';
import { lastValueFrom } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { SistecredInfoComponent } from './sistecred-info/sistecred-info.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { PixelService } from '../../services/pixel/pixel.service';
import { ContraentregaComponent } from '../contraentrega/contraentrega.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { AddiService } from 'src/app/services/addi/addi.service';
import { MetaSellService } from 'src/app/services/meta_sell/meta-sell.service';

export interface image {
  previewImageSrc: string,
  thumbnailImageSrc: string,
  alt: string,
  title: string
}

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent {

  product!: any;
  loaded = false;
  loading = true;
  originalPrice!: any;
  discountPrice = 0;
  offer!: any;
  discount!: any;
  subData: any[] = [];
  splitDescription!: any[];
  sizeDescription!: number;
  images: any = [{}]
  displayModal=false;
  order!: any;
  responsiveOptions: any[] = [
    { breakpoint: '1500px', numVisible: 7 },
    { breakpoint: '1024px', numVisible: 5 },
    { breakpoint: '768px', numVisible: 3 },
    { breakpoint: '560px', numVisible: 2}
  ];
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }
  type:number = 1;

  displayDialogBuyNow:boolean = false;
  currentAddress:any;
  discountBuyNow:number = 0;
  subTotal:number = 0;
  prices = [];
  quotation:any;
  deliveryPrice = 0;
  realDeliveryPrice = 0;
  installPrice = 0;
  total:number = 0;
  units: number = 1;
  showSkeleton: boolean = false;
  showSkeletonPricesInfo: boolean = false;
  Favorites: any = null;
  noFavorites = false;
  page = 1;
  continueResults = true;
  hasAnyAd!:number;

  displayBasic!: boolean;
  displayBasic2!: boolean;
  displayCustom!: boolean;
  activeIndex: number = 0;
  screenHeight!:number;
  skeleton = false;
      
  destDaneCode: string = "";
  allyInfo:any;
  infoUser:any;


  retreatCart: ShopCar = { type: 0, sells: [] };
  deliveryLiquidCart: ShopCar = { type: 1, sells: [] };
  deliveryParcelCart: ShopCar = { type: 1, sells: [] };
  deliveryCommodityCart: ShopCar = { type: 1, sells: [] };

  fleteTotal:any;
  observaciones:string = "";
  locationId:any;
  showDialogAddress:boolean = false;
  addresses!: any[];
  showSkeletonAddress:boolean = false;
  select!:any;
  disableIonRadio:boolean = false;
  showDialogCreateAddress:boolean = false;
  islogged:boolean = false;
  shippingHasInfo:boolean = true;

  isMobile = false;
  selectedVariant: any;

  haveStock = false;
  
  showCouponDialog=false;
  couponCodeDiscount: string;
  couponDiscount: any = undefined;
  couponCodeDelivery: string;
  couponDelivery: any = undefined;
  couponCode: string;

  sistecreditDialog = false;
  idTypes = [{value: 'CC'}, {value: 'CE'}];
  idType: any;
  idnumber: any;
  loadingSiste = false;
  _fbp: string|undefined;


  paymentDialog: any;
  paymentMethod: any;
  sistecredit = false;
  contra = false;

  noVehiclesToDateModal = false;

  actualAmountVehicles = 0;

  continueLoading= true;

  allow_addi = false;

  addiDialog = false;

  department: any;

  city: any;

  email = "";

  loadingaddi = false;

  isfiltered = true;


  @ViewChild('addi') addi!: ElementRef;

  constructor(private shop_service: ShopService,
              private route: ActivatedRoute,
              private carzService: CarzService,
              private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private delivery_service: DeliveryService,
              private location: Location,
              private shopCarService: ShopCartService,
              private messageService: MessageService,
              private userProfile: UserprofileService,
              private buysService: BuysService,
              private alliesService: AlliesService,
              private accountInfo: AccountService,
              private router: Router,
              private favoriteService: FavoriteService,
              private garageService: GarageService,
              private generalConfigurationService: GeneralConfigurationService,
              private addressService: AddressService,
              private checkoutService: CheckoutService,
              private gtmService: GoogleTagManagerService,
              private pixel: PixelService,
              private couponService: CouponsService,
              public dialogService: DialogService,
              public addiService: AddiService,
              public metaSellService: MetaSellService
              ) { }

  async ngOnInit() {
    this._fbp = this.generalConfigurationService.getCookie('_fbp');
    this.isMobile = this.generalConfigurationService.isMobile;
    this.screenHeight = screen.height;
    this.route.queryParams.subscribe(async (params:any) => {
      this.islogged = await this.accountInfo.isLogged();
      this.isfiltered = localStorage.getItem("filterPrincipalVehicle") === 'true';
      this.loaded = false;
      this.order = params;
      this.images = [];
      this.gotoTop();
      await this.gettingProduct(this.order?.id);
    });
    await this.getUserAddress();
    if(!this.destDaneCode){
      this.type = 0;
    }
  }

  checkAddi(){
    /* this.allow_addi = this.discountPrice < 3000000 && this.discountPrice > 50000; */
    this.addiService.checkPrices(this.discountPrice).subscribe((data) => {
      this.allow_addi = this.discountPrice < data.maxAmount && this.discountPrice > data.minAmount;
      let html = '<addi-widget price=\"' + this.discountPrice.toString() + '\" ally-slug=\"zaito-ecommerce\"></addi-widget>'
      this.addi.nativeElement.innerHTML = html;
    });
  }

  showSiste() {
    const ref = this.dialogService.open(SistecredInfoComponent, {
      dismissableMask: true,
      showHeader: true,
      width: this.isMobile ? '90%' : '40%',
      height:  this.isMobile ? '90%' : '65%'});
  }

  continueSiste(){
    this.sistecreditDialog = true;
  }
  
  checkStock(){
    const workshopId = this.product.workship_idworkship.idworkship;
    const weight = this.type == 1 ? this.product.dimensions.weight : undefined;
    if(this.product.variants?.length > 0){
      if(this.product.variants[0].cloth_size){
        let cont = 0
        this.product.variants.forEach((variant: any) => {
          const actualCartStock = this.shopCarService.getLineStock(
            this.product.idproduct,workshopId,variant.id, weight
          )
          if (variant.stock > 0 && actualCartStock > 0){
            variant.stock = variant.stock - actualCartStock;
          }
        })
      }else{
        const actualCartStock = this.shopCarService.getLineStock(
          this.product.idproduct,workshopId, undefined, weight
        )
        this.product.stock = this.product.stock - actualCartStock;
      }
    }else{
      const actualCartStock = this.shopCarService.getLineStock(
        this.product.idproduct,workshopId, undefined, weight
      )
      this.product.stock = this.product.stock - actualCartStock;
    }
  }

  showPaymentInfo(){
    const ref = this.dialogService.open(PaymentInfoComponent, {dismissableMask: true, showHeader: true, width: this.isMobile ? '90%' : '35%', height: '90%'});
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  selectVariant(i: number){
    if(this.product.variants[i].stock !== 0){
      this.selectedVariant = i
    }
  }

  private async gettingProduct(idProduct: any) {
    const filter = this.garageService.getValueFilterVehiclePrincipal();
    await this.shop_service.getProduct(idProduct, filter).then((data) => {
      this.product = data;
      this.checkStock();
      if (data.get_in_workship && ! data.delivery){
        this.type = 0;
      }
      if(data.variants?.length > 0){
        if(data.variants[0].cloth_size){
          let cont = 0
          data.variants.forEach((variant: any) => {
            if(variant.stock > 0 && this.selectedVariant  === undefined){
              this.selectedVariant = cont;
              this.haveStock = true;
              return;
            }
            cont = cont + 1;
          })
        }else{
          this.product.stock = this.product.variants[0].stock;
          this.product.variants = []
          this.haveStock = this.product.stock ;
        }
      }else{
        this.haveStock = data.stock > 0;
      }
      this.hasAnyAd = data.productAd.length;
      this.sizeDescription = data.description?.length;
      this.splitDescription = this.product.description?.split("^~");
      const tax = this.product.tax === null ? 0 : this.product.tax;
      const installTax = this.product.install_tax === null ? 0 : this.product.install_tax;
      this.discount = this.product.productAd.length === 0 ? 0 : this.product.productAd[0]?.discount;
      this.offer = this.product.productAd.length === 0 ? 0 : this.product.productAd[0]?.offer;
      this.originalPrice = this.product.prize * (1 + (tax / 100));
      this.installPrice = this.product.install_prize * (1 + (installTax/100))
      this.subTotal = this.originalPrice;
      if (this.product.productAd[0]?.type === 1) {
        this.discountPrice = this.originalPrice - (this.originalPrice * (this.discount / 100));
      } else if (this.product.productAd[0]?.type === 2) {
        this.discountPrice = this.originalPrice - this.offer;
      } else {
        this.discountPrice = this.originalPrice
      }      
      this.offer = Math.round((this.discountPrice * 100 / this.originalPrice) - 100);
      this.offer.toFixed(2)
      data?.id_image?.forEach((image: any, conta: number) => {
        if(image.principal){
          this.images.unshift({
            "previewImageSrc": image.image_idimage.url,
            "thumbnailImageSrc": image.image_idimage.url,
            "alt": (conta).toString(),
            "title": "titulo" + conta
          })
        }else{
          this.images.push({
            "previewImageSrc": image.image_idimage.url,
            "thumbnailImageSrc": image.image_idimage.url,
            "alt": (conta).toString(),
            "title": "titulo" + conta
          });
        }
      });
      this.loaded = true;
      this.subData = [];
      this.createDescripcion(this.splitDescription);
      this.checkAddi();
    }).catch((error) => {
      this.router.navigateByUrl('/shop');
      console.log(error)
    }
    );
  }

  createDescripcion(data:any) {
    for (var i = 1; i < data.length; i++){
      this.subData.push(data[i].split(":"));
    }
  }

  calPrices() {
    this.subTotal = this.originalPrice;
    const installTax = this.product.install_tax === null ? 0 : this.product.install_tax;
    this.installPrice = this.product.install_prize * (1 + (installTax/100));
    let tax = this.product.tax ? (this.product.prize * (this.product.tax / 100)) : 0;
    let base_price = this.product.prize;
    let  realPrice = base_price + tax;
    this.total = realPrice;
    const discount = this.product.productAd.length !== 0 && this.product.productAd[0]?.type === 1;
    const offer = this.product.productAd.length !== 0 && this.product.productAd[0]?.type === 2 ;
    if (discount) {
      this.discountBuyNow = this.subTotal * (this.product.productAd[0]?.discount / 100);
      realPrice = realPrice - (realPrice * (this.product.productAd[0]?.discount / 100));
      this.total = this.total - this.discountBuyNow;
    } else if (offer) {
      this.discountBuyNow = this.product.productAd[0]?.offer;
      realPrice = realPrice - this.product.productAd[0]?.offer;
      this.total = this.total - this.product.productAd[0]?.offer;
    }else if(this.couponDiscount){
      this.discountBuyNow = this.subTotal * (this.couponDiscount.campaing.promotion_value/ 100);
      realPrice = realPrice - (realPrice * (this.couponDiscount.campaing.promotion_value/ 100));
      this.total = this.total - this.discountBuyNow;
    }else{
      this.discountBuyNow = 0;
    }
    base_price = realPrice / (1 + (this.product.tax / 100));
    tax = base_price * (this.product.tax / 100);
    this.installPrice = this.installPrice * this.units;
    this.total = this.total * this.units;
    if (this.type == 2){
      this.total+= this.installPrice;
    }
    this.subTotal = this.subTotal * this.units;
    this.discountBuyNow = this.discountBuyNow * this.units;
    this.continueLoading = false;
    return [realPrice, base_price, tax];
  }

  openModalDescription() {
    this.displayModal = true;
  }

  dissmissModal() {
    this.displayModal = false;
  }

  openModalCarz(){
    const objectOpenModal = {
      modal:false, 
      modalChat: {
        active:true, 
        text: "Tengo una duda sobre: " + this.product.name,
        isProduct: true
      }
    }
    this.carzService.ObserModalOpen.next(objectOpenModal)
  }

  precheckoutTrigg(){
    const gtmTag = {
      event: 'Precheckout',
      data: {currency: 'COP', value: this.originalPrice, 
        content_ids: this.product.idproduct,ontent_name : this.product.name},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('precheckout', {
      content_name : this.product.name,
      currency: 'COP',
      value: this.originalPrice,
      content_ids: this.product.idproduct
    });
  }

  checkoutTrigg(id: string) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'begin_checkout',
      data: {currency: 'COP', value: this.originalPrice},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('InitiateCheckout', {
      content_name : this.product.name,
      currency: 'COP',
      value: this.originalPrice,
      content_ids: this.product.idproduct
    }, {event_id: id});
  }

  itemTrigg() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'view_item',
      data: {currency: 'COP', value: this.originalPrice, items: [{item_id: this.product.idproduct, item_name: this.product.name}]},
    };
    this.gtmService.pushTag(gtmTag);
  }

  addWishListTrigg(){
    const gtmTag = {
      event: 'add_to_wishlist',
      data: {currency: 'COP', value: this.originalPrice, 
        items: [
          {
            item_id: this.product.idproduct,
            item_name: this.product.name
          }
        ]},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('AddToWishlist', {
      content_category: 'page',
      content_name : 'AddToWishlist',
      currency: 'COP',
      value: this.originalPrice,
      content_ids: this.product.idproduct
    });
  }

  async buyAddi(){
    this.loadingaddi = true;
    let enviosObj;
    if(this.type) {
      enviosObj = await this.infoGeneral();
    }
    await this.separateTypeProduct(this.product.workship_idworkship.idworkship, this.product.idproduct, this.product.dimensions, this.product.is_liquid);
    const sell = await this.reviewPackages(enviosObj);
    sell!.user_type_id = 'Cédula de ciudadanía';
    sell!.user_number_id = this.idnumber;
    sell!.email = this.email;
    const total = this.total;
    this.delivery_service.checkout(sell).then(async (data: any) => {
      this.checkoutTrigg(data.idventa);
      await this.metaSellService.saveSell([data.idventa]);
      this.addiService.getLink(""+data.idventa).subscribe((link) => {
        window.location.replace(link.url)
        /* this.loadingaddi = false; */
      }, error => {
        this.loadingaddi = false;
        this.addiDialog = false;
      })
    }).catch((error )=> {
      console.log(error);
    });
  }

  async buySiste(){
    let enviosObj;
    if(this.type) {
      enviosObj = await this.infoGeneral();
    }
    await this.separateTypeProduct(this.product.workship_idworkship.idworkship, this.product.idproduct, this.product.dimensions, this.product.is_liquid);
    const sell = await this.reviewPackages(enviosObj);
    const total = this.total;
    this.delivery_service.checkout(sell).then(async (data: any) => {
      this.checkoutTrigg(data.idventa)
      await this.metaSellService.saveSell([data.idventa]);
      const body = {
        idDocument: this.idnumber,
        typeDocument: this.idType.value,
        transactionDate: new Date(),
        valueToPaid: total,
        vendorId: environment.vendorId,
        storeId: environment.storeId,
        orderId: data.idventa,
        responseUrl: environment.checkout_url + "/sistecreditoWeb"
      }
      const bodyfire = {data : data.idventa};
      this.buysService.saveSell(data.idventa,bodyfire);
      lastValueFrom(this.buysService.getSisteLink(body)).then(response => {
        const url = response.data.urlToRedirect + "?transactionId=" + response.data.transactionId;
        window.location.replace(url);
        localStorage.removeItem("sells");
        localStorage.setItem("sells", JSON.stringify(data.idventa));
      }).catch((error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: 'Existe una transacción en curso, inténtalo más tarde.', life: 4000});
      });
    });
  }

  async buyNow() {
    let enviosObj;
    if(this.type) {
      enviosObj = await this.infoGeneral();
    }
    await this.separateTypeProduct(this.product.workship_idworkship.idworkship, this.product.idproduct, this.product.dimensions, this.product.is_liquid);
    const sell = await this.reviewPackages(enviosObj);
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    const prices = this.prices;
    let delivery_price = 0;
    if(this.type == 1){ 
      delivery_price = this.deliveryPrice;
    }
    const total = (prices[0] * this.units ) + delivery_price ;
    const base = (prices[1] * this.units);
    const tax = prices[2] * this.units;
    const ownUrl = window.location.hostname;
    this.delivery_service.checkout(sell).then((data: any) => {
      this.checkoutTrigg(data.idventa)
      script.text = `
                    var handler = ePayco.checkout.configure({
                      key: '4bb75aee733562d3de6aaccfb9f7390a',
                      test: ${environment.production ? 'false' : 'true'}
                    });
                    var data={
                      //Parametros compra (obligatorio)
                      name: "Compra Zaito",
                      description: "${this.product.name}",
                      currency: "cop",
                      amount: ${total},
                      tax_base: ${base},
                      tax: ${tax ? tax : 0},
                      country: "co",
                      lang: "es",

                      //Onpage="false" - Standard="true"
                      external: "false",

                      extra1: "${data.idventa}",
                      extra2: "${this._fbp}",
                      //Atributos opcionales
                      confirmation: "${environment.checkout_url}/payweb/",
                      acepted: "https://www.zaito.co/payment/accepted?ids=${data.idventa}",
                      pending: "https://www.zaito.co/payment/pending",
                      rejected: "https://www.zaito.co/payment/rejected",
                      response: "https://www.zaito.co/",
                    }
                    handler.open(data)`;
      this._renderer2.appendChild(this._document.body, script);
    });
  }

  async buyContrentrega(data: any){
    let enviosObj;
    if(this.type) {
      enviosObj = await this.infoGeneral();
    }
    await this.separateTypeProduct(this.product.workship_idworkship.idworkship, this.product.idproduct, this.product.dimensions, this.product.is_liquid);
    const sell = await this.reviewPackages(enviosObj);
    sell!.franchie = "CON";
    sell!.payment_gateway_id = sell?.delivery?.remission_code;
    sell!.payed_value = this.total;
    sell!.payed_date = new Date();
    sell!.user_type_id = data.idType;
    sell!.user_number_id = data.idNumber;
    this.delivery_service.checkout(sell).then(async (data: any) => {
      this.checkoutTrigg(data.idventa);
      await this.metaSellService.saveSell([data.idventa]);
      this.paymentDialog = false;
      if(this.paymentMethod === "contraentrega"){
        lastValueFrom(this.delivery_service.createBill(data.idventa)).catch((error) => {
          console.log(error);
        })
      }
      this.router.navigateByUrl(`payment/accepted?ids=${data.idventa}`)
    }).catch((error) => {
      this.messageService.add({severity: "error", summary: "Error", detail: 'Hubo un error al momento de crear tu compra, inténtalo mas tarde.', life: 4000});
    })
  }

  async infoGeneral(): Promise<Partial<ShippingObject>> {
    const date = new Date()
    const fecha = date.toUTCString();
    let direccion_destinatario = ""
    direccion_destinatario = this.currentAddress.split(",", 1)[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    const ciudad_destinatario = this.destDaneCode;
    const telefono_destinatario = this.infoUser[0].celphone;
    let nombre = this.infoUser[0].auth_user.first_name + " " + this.infoUser[0].auth_user.last_name;
    nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    const enviosObj = {fecha, direccion_destinatario, ciudad_destinatario, telefono_destinatario, estado: "IMPRESO", nombre_destinatario: nombre};
    return enviosObj;
  }

  separateTypeProduct(workshop:number, idproduct:number, dimensions:any, is_liquid:boolean) {
    if (this.type == 0) {
      this.retreatCart = { type: 2, sells : [{ workshop, sellLines: [{ product: idproduct, units: this.units, date: new Date() }] }]};
    } else if (this.type == 1) {
      if (is_liquid) {
        this.deliveryLiquidCart = { type: 1, sells : [{ workshop, sellLines: [{ product: idproduct, units: this.units, date: new Date() }] }]};
      } else {
        if (dimensions.weight && dimensions.weight > 5) {
          this.deliveryCommodityCart = { type: 1, sells : [{ workshop, sellLines: [{ product: idproduct, units: this.units, date: new Date() }] }]};
        } else {
          this.deliveryParcelCart = { type: 1, sells : [{ workshop, sellLines: [{ product: idproduct, units: this.units, date: new Date() }] }]};
        }
      }
    }
  }

  async reviewPackages(enviosObj:any) {
    const sells: Sell[] = [];
    if (this.deliveryParcelCart.sells.length > 0) {
      try {
        const delivery = await this.infoEnvio(this.deliveryParcelCart.sells[0].workshop, this.deliveryParcelCart.sells[0].sellLines, enviosObj, "Parcel", false);
        if (delivery instanceof HttpErrorResponse) {
          throw new Error(delivery.error);
        }
        const deliveryPrize = this.realDeliveryPrice;
        const sell = await this.createSells(this.deliveryParcelCart.sells[0], sells, 1, delivery, deliveryPrize);
        return sell;
      } catch (error) {
        console.log(error);
      }
    } else if (this.deliveryCommodityCart.sells.length > 0) {
      try {
        const delivery = await this.infoEnvio(this.deliveryCommodityCart.sells[0].workshop, this.deliveryCommodityCart.sells[0].sellLines, enviosObj, "Commodity", false);
        if (delivery instanceof HttpErrorResponse) {
          throw new Error(delivery.error.error);
        }
        const deliveryPrize = this.realDeliveryPrice;
        const sell = await this.createSells(this.deliveryCommodityCart.sells[0], sells, 1, delivery, deliveryPrize);
        return sell;
      } catch (error) {
      }
    } else if (this.deliveryLiquidCart.sells.length > 0) {
      try {
        const delivery = await this.infoEnvio(this.deliveryLiquidCart.sells[0].workshop, this.deliveryLiquidCart.sells[0].sellLines, enviosObj, "liquid", true);
        if (delivery instanceof HttpErrorResponse) {
          throw new Error(delivery.error.error);
        }
        const deliveryPrize = this.realDeliveryPrice;
        const sell = this.createSells(this.deliveryLiquidCart.sells[0], sells, 1, delivery, deliveryPrize);
        return sell;
      } catch (error) {
      }
    } else if (this.retreatCart.sells.length > 0){
      const sell = await this.createSells(this.retreatCart.sells[0], sells, 0);
      return sell;
    }
    return null;
  }

  private createSells(sell: ShopCarSells, sells: Sell[], sellType: number, delivery?:any, deliveryPrize?:any) {
    let aux: Sell;
    const sellLines = sell.sellLines.map((sellLine:any) => {
      return { units: sellLine.units, product_idproduct: sellLine.product, variant: this.product?.variants[this.selectedVariant]?.id};
    });
    if (sellType === 1) {
        aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, delivery_prize: deliveryPrize,
                delivery: {
                  guide: delivery.guide, remission_code: delivery.remission_code, userAddress: delivery.userAddress,
                  userLocation: delivery.userLocation, ally_addres: delivery.ally_Address,
                  ally_location: delivery.ally_Location, guide_link: delivery.guide_link, user_description: delivery.user_description
                }, from_webpage: true, coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id
              };
    } else if (sellType === 0) {
      aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, 
              from_webpage: true, coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id };
    }
    // @ts-ignore
    return aux;
  }

  get variantName(){
    return this.product?.variants[this.selectedVariant]?.cloth_size ? this.product?.variants[this.selectedVariant]?.cloth_size : ''
  }

  async infoEnvio(workshopId: number, sellLines: SellLine[], enviosObj: Partial<ShippingObject>, shippingType: string, is_liquid?:boolean): Promise<DeliveryObject> {
    let detalles:any = [];
    const contenido:any = [];
    let valorDeclarado = 0;
    let names = "";
    let dimensionsAux: PackageDimensions = {
      alto: 0,
      ancho: 0,
      largo: 0,
      peso: 0,
      unidades: 1,
    }
    let max_volume = 0
    sellLines.forEach((sellLine, index) => {
      const detalleProduct:any = this.getProductInfo(sellLine, shippingType);
      if (detalleProduct.discount > 0) {
        const originalPrice = (detalleProduct.price * (1 + (detalleProduct.tax / 100))) - detalleProduct.discount;
        const pricewithouthIva = Math.ceil(originalPrice / (1 + (detalleProduct.tax / 100)));
        valorDeclarado += pricewithouthIva;
      } else {
        valorDeclarado += detalleProduct.price;
      }
      const dimensions = detalleProduct.dimensions
      let volume = dimensions.alto * dimensions.ancho * dimensions.largo;
      if(volume > max_volume){
        max_volume = volume;
        detalles = [detalleProduct.dimensions];
      }
      contenido.push(detalleProduct.name);
      names = names+detalleProduct.name[0]
    });
    enviosObj.nit_remitente = this.allyInfo.enterprise.number_id;
    enviosObj.nombre_remitente = this.allyInfo.visible_name;
    enviosObj.direccion_remitente = this.allyInfo.address.split(",", 1)[0];
    enviosObj.telefono_remitente = this.allyInfo.phoneNumber;
    enviosObj.ciudad_remitente = this.allyInfo.city.dane_code;
    enviosObj.detalles = detalles;
    enviosObj.contenido = contenido.join().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    enviosObj.valor_declarado = valorDeclarado;
    enviosObj.observaciones = this.observaciones.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    try {
      let dataResp: any = undefined;
      if (this.paymentMethod === 'contraentrega' && environment.production == true){
        let nameaux = enviosObj.nombre_remitente?.charAt(0)
        const unixTime = Math.floor(Date.now() / 1000);
        enviosObj.referencia = names + (nameaux !== undefined ? nameaux : '') + unixTime + ""
        enviosObj.valor = this.total + ""
        dataResp = await this.delivery_service.postGuiaContra(enviosObj);
      }else{
        dataResp = await this.delivery_service.postGuia(enviosObj, this.product.is_liquid);
      }
      const guide = dataResp.id_remision["#text"];
      const remission_code = dataResp.codigo_remision["#text"];
      const userAddress = this.currentAddress.split(",", 1)[0];
      const ally_Address = this.allyInfo.address.split(",", 1)[0];
      const ally_Location = this.allyInfo.city.id;
      const guide_link = dataResp.url_terceros["#text"];
      const user_description = this.observaciones.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
      const delivery: DeliveryObject = {
          guide, remission_code, userLocation: this.locationId,
          userAddress, ally_Address, ally_Location, guide_link, user_description
      };
      return delivery;
    } catch (error:any) {
      if (error.error?.error) {
      } else {
      }
      return error;
    }
  }

  private getProductInfo(sellLine: SellLine, shippingType: string): CheckoutValues {
    const product = this.product;
    const discount = this.setProductDiscount(product);
    let dimensions: PackageDimensions | any = null;
    if (product.dimensions !== null) {
      if (shippingType === "Parcel") {
        dimensions = this.setCommodityDimensions(product, sellLine.units);
      } else if (shippingType === "Commodity") {
        dimensions = this.setCommodityDimensions(product, sellLine.units);
      }else if (shippingType === "liquid"){
        dimensions = this.setParcelDimensions(product, sellLine.units);
      }
    }
    const data: CheckoutValues = {
      name: product.name,
      price: product.prize,
      discount,
      installPrice: product.install_prize ,
      installTax: product.install_tax,
      dimensions,
      tax: product.tax,
      idproduct: product.idproduct
    };
    return data;
  }

  private setProductDiscount(product: Product): number {
    let discount:any = 0;
    if (product.productAd.length > 0) {
      if (product.productAd[0].type === 1) {
        discount = (product.productAd[0].discount / 100) * product.prize;
      } else if (product.productAd[0].type === 2) {
        discount = product.productAd[0].offer;
      }
    }
    return discount;
  }

  private setParcelDimensions(product: Product | any, units: number): PackageDimensions {
    const dimensions: PackageDimensions = {
      alto: product.dimensions.height * units,
      ancho: product.dimensions.width * units,
      largo: product.dimensions.length * units,
      peso: product.dimensions.weight * units,
      unidades: 1
    };
    return dimensions;
  }

  private setCommodityDimensions(product: Product | any, units: number): PackageDimensions {
    const dimensions: PackageDimensions = {
      alto: product.dimensions.height,
      ancho: product.dimensions.width,
      largo: product.dimensions.length,
      peso: product.dimensions.weight,
      unidades: units
    };
    return dimensions;
  }

  goBack() {
    this.location.back();
  }

  putInCar(): void {
    let haveStock = false;
    let stock = 0;
    if(this.selectedVariant !== undefined){
      haveStock = this.product.variants[this.selectedVariant].stock > 0;
      stock = this.product.variants[this.selectedVariant].stock;
    }else{
      haveStock = this.product.stock > 0;
      stock = this.product.stock;
    }
    const workshopId = this.product.workship_idworkship.idworkship;
    const weight = this.product.dimensions.weight;
    const variant = this.product?.variants[this.selectedVariant]?.id ? this.product?.variants[this.selectedVariant]?.id : undefined
    if (!haveStock) {
      this.setMsgError("Las cantidades seleccionadas no se encuentran disponibles.");
    } else {
      const success = this.shopCarService.adToCar(1, this.product.idproduct, workshopId, weight, this.product.is_liquid, variant);
      if (success) {
        this.shopCarService.saveCar();
        this.cartTagTrigg(this.discountPrice, this.product.name, this.product.idproduct)
        this.setMsgSuccess(this.product.name + " ha sido agregado al carrito!");
        if(this.selectedVariant !== undefined){
          this.product.variants[this.selectedVariant].stock--;
        }else{
          this.product.stock--;
        } 
      } else {
      }
    }
  }

  cartTagTrigg(price: any, name: any, id: any) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'add_to_cart',
      data: {currency: 'COP', value: price, items:[{item_name: name, item_id: id}]},
    }; 
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('AddToCart', {
      content_name : name,
      currency: 'COP',
      value: price,
      content_ids: id
    });
    
  }

  setMsgSuccess(msg: string) {
    this.messageService.add({
      severity: "success",
      summary: "Éxito",
      detail: msg,
      life: 3000
    });
  }

  setMsgError(msg: string) {
    this.messageService.add({
      severity: "error",
      summary: "Error!",
      detail: msg,
      life: 6000
    });
  }


  async buyNowDialog() {
    if (this.islogged) {
      this.precheckoutTrigg();
      this.displayDialogBuyNow = true;
      this.showSkeleton = true;
      this.showSkeletonPricesInfo = true;
    } else {
      this.checkoutService.productNoLogin = this.product;
      const workshopId = this.product.workship_idworkship.idworkship;
      const weight = this.product.dimensions.weight
      const variant = this.product?.variants[this.selectedVariant]?.id ? this.product?.variants[this.selectedVariant]?.id : undefined
      const success = this.shopCarService.adToCar(1, this.product.idproduct, workshopId, weight, this.product.is_liquid, variant);
      if (success) {
        this.shopCarService.saveCar();
        this.router.navigate(['checkout'])
      }
    }
    /* this.messageService.add({severity: "error", summary: "Error", detail: 'El vehiculo necesita de una placa para poder agendar citas', life: 8000}); */
  }

  private redondearAlPiso(numero: number): number {
    const factor = 100; // establece el factor de redondeo, en este caso 100 para los últimos dos dígitos
    return Math.floor(numero / factor) * factor; // divide el número por el factor, aplica Math.floor y luego multiplica por el factor
  }

  private isParcel(height: number, width: number, weight: number, length: number){
    if(height > 5 || weight > 5 || width > 5 || length > 5){
      return false;
    }else {
      return true;
    }
  }

  async onShowDialogBuyNow() {
    this.showSkeletonPricesInfo = true;
    // @ts-ignore
    this.prices = this.calPrices();
    
    if(this.type == 1){
      await this.getUserAddress();
      this.infoUser = await this.userProfile.getInfoUser().toPromise();
      this.allyInfo = await this.alliesService.getInfoMarker(this.product.workship_idworkship.idworkship);
      const promises: Promise<any>[] = [];
      if (this.destDaneCode) {
        let isParcel = this.isParcel(this.product.dimensions.height, this.product.dimensions.width, this.product.dimensions.weight, this.product.dimensions.length);
        promises.push(this.delivery_service.getShippingQuotation(this.allyInfo.city.dane_code, this.destDaneCode, this.prices[0], this.product.dimensions.height,
                              this.product.dimensions.width, this.product.dimensions.length, this.product.dimensions.weight, this.units, this.product.is_liquid, isParcel));
        if(this.couponDelivery){
          promises.push(this.buysService.getPercentage(4));
        }else{
          promises.push(this.buysService.getPercentages(this.product.idproduct, this.destDaneCode));
        }
        Promise.all(promises).then(async data => {
          const quoteInfo = data[0] === null ? data[0] : data[0].Cotizador_cotizarResult
          if (quoteInfo) {
            /* const shippingPrice =  */
            this.quotation = quoteInfo;
            this.realDeliveryPrice = quoteInfo.flete_total
            if(data[1].fixed_delivery_price){
              this.deliveryPrice = data[1].fixed_delivery_price;
            }else{
              this.deliveryPrice = this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (data[1].user_shipping / 100)));
            }
            /* =  this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (data[1].user_shipping / 100))); */
            this.fleteTotal = Math.ceil(parseInt(this.quotation.flete_total));
            this.total = this.total + this.deliveryPrice;
            this.showSkeleton = false;  
            this.showSkeletonPricesInfo = false;
            this.shippingHasInfo = true;
          } else {
            this.shippingHasInfo = false;
          }
        }).catch((error) => {
          this.setMsgError("No se pudo realizar la compra, intenta de nuevo")
          this.showSkeleton = false;
          this.showSkeletonPricesInfo = false;
          this.shippingHasInfo = false;
        });
      } else {
        this.openDialogCreateAddress();
      }
    }else{
      this.showSkeleton = false;
      this.showSkeletonPricesInfo = false;
      this.shippingHasInfo = false;
    }
  }

  private async getUserAddress(): Promise<void> {
    await this.addressService.getLocation().then( async resp => {
      this.addressService.$updateAddress.next(true);
      /* console.log(resp); */
      this.addresses = resp;
      this.disableIonRadio = false;
      if (resp.length > 0) {
        const currentAddress = resp.find((data: any) => data.current === true);
        if (currentAddress) {
          this.currentAddress = currentAddress.address? currentAddress.address : null;
          this.destDaneCode = currentAddress.location.dane_code? currentAddress.location.dane_code : null;
          this.observaciones = currentAddress.description;
          this.locationId = currentAddress.location.id;
          this.select = currentAddress.current;
        } else {
          await this.addressCurrent(this.addresses[0].id);
        }
      }
      this.showSkeletonAddress = false;
    }).catch(error => {
    });
  }

  async addressCurrent(id: number): Promise<void> {
    this.showSkeletonAddress = true;
    if (!this.disableIonRadio) {
      this.disableIonRadio = true;
      await this.addressService.currentLocation(id, true).then( async data => {
        await this.getUserAddress();
      }).catch( error => {
      });
    }
  }

  

  markFavorite() {
    const token = localStorage.getItem("token");
    if(token && (this.product?.is_wished == null) || token && (this.product?.is_wished.length == 0)) {
      this.favoriteService.markAsFavorite(this.product.idproduct).subscribe((data: any) => {
        this.addWishListTrigg();
        this.product.is_wished = [data.id_wish];
        this.messageService.add({ 
          severity: 'success', summary: 'Favoritos', 
          detail: this.product.name + ' Se ha agregado a favoritos',
          life: 3000
        });
      });
    } else if (token) {
      this.favoriteService.deleteFavorite(this.product.is_wished[0]).subscribe((data: any) => {
        this.product.is_wished = data;
      });
    } else if (token == null) {
      this.router.navigate(['login']);
    }
  }

  changeUnits(mode: string = 'add' || 'subs') {
    let unit = 0;
    if(this.selectedVariant){
      unit = this.product.variants[this.selectedVariant].stock;
    }else{
      unit = this.product.stock;
    }
    if ( mode === 'add' && this.units < unit) {
      this.units++;
    } else if ( mode === 'subs' && this.units > 1) {
      this.units--;
    }
    this.initializeVariables();
    this.onShowDialogBuyNow();
  }

  getFavorites(extraLoad?:any, filtered?: boolean) {
    this.favoriteService.getFavorites(this.page).then(data => {
      if (filtered) {
        this.Favorites = data.results;
      } else {
        this.Favorites = this.Favorites.concat(data.results);
      }
      if (data.next) {
        this.page++;
        this.continueResults = true;
      } else {
        this.continueResults = false;
      }
      if (extraLoad) {
        extraLoad.target.complete();
      }
      // this.setTotalProducts();
      if (this.Favorites.length === 0) {
        this.noFavorites = true;
      } else {
        this.noFavorites = false;
      }
    }, error => console.log("error de conexion"));
  }
  
  async changeAddress(id: number): Promise<void> {
    this.showDialogAddress = false;
    this.showSkeletonAddress = true;
    if (!this.disableIonRadio) {
      this.disableIonRadio = true;
      await this.addressService.currentLocation(id, true).then( async data => {
        await this.onShowDialogBuyNow();
      }).catch( error => {
      });
    }
  }

  openDialogDirection() {
    this.showDialogAddress = true;
  }

  openDialogCreateAddress() {
    this.showDialogCreateAddress = true;
  }

  emitCreateAddress(e:any) {
    this.showDialogCreateAddress = false;
    this.showSkeletonAddress = true;
    this.getUserAddress();
    if(e.current){
      this.onShowDialogBuyNow();
    }
    if (!this.showDialogAddress) {
      this.showDialogAddress = true;
    }
  }

  closeDialogCreateAddress() {
    this.showDialogCreateAddress = false;
    if (this.addresses.length == 0) {
      this.type = 0;
      this.displayDialogBuyNow = false;
    }
  }

  initializeVariables() {
    this.subTotal = 0;
    this.deliveryPrice = 0;
    this.discountBuyNow = 0;
    this.installPrice = 0;
  }

  apply(){
    if(this.couponCode && this.couponCode !== ""){
      lastValueFrom(this.couponService.checkCoupon(this.couponCode.toUpperCase())).then((data: any) => {
        /* this.coupon = data; */
        if (data.campaing.promotion_type === 1){
          if(this.couponDelivery){
            this.messageService.add({severity: "error", summary: "Error", detail: 'Ya tiene un cupón de envio aplicado', life: 4000});
          }else{
            this.couponDelivery = data;
            this.onShowDialogBuyNow();
            this.showCouponDialog = false;
          }
        }else{
          if(this.couponDiscount || this.discountBuyNow > 0){
            this.messageService.add({severity: "error", summary: "Error", detail: 'Hay productos en la compra con descuento solo se permiten cupones de Envíos gratis', life: 4000});
          }else{
            this.couponDiscount = data;
            // @ts-ignore
            this.onShowDialogBuyNow();
            this.showCouponDialog = false;
          }
        }
      }).catch((error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: error.error.Error, life: 4000});
      })
    }
  }

  async changeType(type: any){
    if(this.isfiltered){
      this.continueLoading = true;
      if (type == 2){
        let garage = await this.garageService.getState()
        this.actualAmountVehicles = garage.length
        if (this.actualAmountVehicles > 0){
          this.onShowDialogBuyNow();
        }else{
          this.noVehiclesToDateModal = true;
        }
      }else{
        this.onShowDialogBuyNow();
      }
    }
  }

  async toPayment(){
    if(this.paymentMethod  === "epayco"){
      this.sistecredit = false;
      this.contra = false;
      this.buyNow();
    }else if (this.paymentMethod === "sistecredit"){
      this.sistecreditDialog = true;
      this.sistecredit = true;
      this.contra = false;
    }else if (this.paymentMethod === "contraentrega") {
      this.sistecredit = false;
      this.showContra();
    }else{
      this.addiDialog = true; 
    }
  }


  showContra(){
    const ref = this.dialogService.open(ContraentregaComponent, {
      data: {
        address: this.currentAddress,
        extra: this.observaciones,
        total: this.total,
        location: this.locationId,
        name: this.infoUser[0].auth_user.first_name,
        last_name: this.infoUser[0].auth_user.last_name,
        phone: this.infoUser[0].celphone
      },
      dismissableMask: false,
      showHeader: true,
      width: this.isMobile ? '100vw' : '30%',
      height:  this.isMobile ? '100vh' : '70%'});
    ref.onClose.subscribe((data) => {
      if(data){
        this.buyContrentrega(data);
      }
    })
  }
  

  async openSchedule(){
    let garage = await this.garageService.getState()
    if (this.islogged && garage.length > 0){
      let height = "";
      let width = "35%"
      if (window.innerWidth < 1400){
        height = '90vh'
        width = "50%"
      }
      let cont = 0;
      const productAux: any[] = []
      let discounts= new Map<number, number>()
      while (cont < this.units) {
        productAux.push(this.product)
        cont = cont + 1
      }
      discounts.set(this.product.idproduct, this.discountBuyNow)
      const ref = this.dialogService.open(ScheduleComponent, {
        closable: true,
        dismissableMask: true,
        showHeader: false,
        width: this.isMobile ? '100vw' : width,
        height: this.isMobile ? '100vh' : height,
        contentStyle: {'overflow-x': 'hidden'},
        data: {products: productAux, discounts, coupon : this.couponDiscount,
          variants: [this.product.variants[this.selectedVariant]?.id]}
      });
      ref.onClose.subscribe((data: any) => {
        if (data.data) {
          this.displayDialogBuyNow = false;
          this.router.navigate(["/history"]);
        }
      });
    }else {
      this.noVehiclesToDateModal = true
    }
  }

  get roundSubTotal(){
    return Math.round(this.subTotal);
  }


  toRegister(){
    this.router.navigateByUrl("login")
  }
}
